import React, { useState } from 'react';

// Material UI
import { styled, withStyles } from '@material-ui/core/styles';
import { Grid, Box, Typography, Tabs, Tab } from '@material-ui/core';
import { TextField, InputAdornment, FormControlLabel, Switch } from '@material-ui/core';
import { RadioGroup, Radio } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Alert } from '@material-ui/lab';

// Components
import ReceiptPreview from 'components/ReceiptPreview';
import SponsorImagePicker from 'components/SponsorImagePicker';
import Tooltip from 'components/popups/Tooltip';
import { TextArea } from 'components/ui/inputs';
import { Section, Header, Content, DeleteButton, CancelButton, SubmitButton } from 'components/ui/forms';

// Material Date Picker
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';

// Utility
import moment from 'moment-timezone';
import { getMomentFromDateAndTime } from 'utility/validation';
import { useNotification } from 'context/notification';



export default function ProductForm(props) {
    // Props
    const { editMode, defaultValues, createdAtMsg, updatedAtMsg, enableSponsor, onCancel, onSubmit, onDelete, loading, deletable, autoFocus, hostId, eventId } = props;

    const [tab, setTab] = useState(0); // 0 = online, 1 = in-person

    const [image, setImage] = useState(defaultValues?.image ?? null);

    // Current value of every form element
    const [state, setState] = useState({
        // Online Ticket Values
        name: defaultValues?.name ?? '',
        price: defaultValues?.price ?? '',
        free: defaultValues?.free ?? false,
        type: defaultValues?.type ?? 1, // This value is set by the parent and cannot be changed
        description: defaultValues?.description ?? '',
        overrideDates: defaultValues?.overrideDates ?? false,
        multipleScan: defaultValues?.multipleScan ?? false,
        startDate: defaultValues?.startDate ?? null,
        startTime: defaultValues?.startDate ?? null,
        endDate: defaultValues?.endDate ?? null,
        endTime: defaultValues?.endDate ?? null,
        saleStartDate: defaultValues?.saleStartDate ?? null,
        saleStartTime: defaultValues?.saleStartDate ?? null,
        saleEndDate: defaultValues?.saleEndDate ?? null,
        saleEndTime: defaultValues?.saleEndDate ?? null,
        quantity: defaultValues?.quantity ?? '',
        minOrder: defaultValues?.minOrder ?? '1',
        maxOrder: defaultValues?.maxOrder ?? '8',
        audience: defaultValues?.audience ?? 'visible',
        accessCode: defaultValues?.accessCode ?? '',
        // POS Values
        posVisible: defaultValues?.posVisible ?? 'hidden',
        posName: defaultValues?.posName ?? '',
        posPrice: defaultValues?.posPrice ?? '',
        posFree: defaultValues?.posFree ?? false,
    })

    // Form elements with invalid data
    const [invalidFields, setInvalidFields] = useState({
        name: false,
        price: false,
        description: false,
        startDate: false,
        startTime: false,
        endDate: false,
        endTime: false,
        saleStartDate: false,
        saleStartTime: false,
        saleEndDate: false,
        saleEndTime: false,
        quantity: false,
        minOrder: false,
        maxOrder: false,
        minLessThanOne: false,
        minOrderOverMax: false,
        minOrderOverQty: false,
        scanRange: false,
        saleRange: false,
        accessCode: false
    })

    // Hooks
    const { notify } = useNotification();


    function handleSubmit() {
        if (validateFields()) {
            const { name, free, type, description, startDate, startTime, endDate, endTime, saleStartDate, saleStartTime, saleEndDate, saleEndTime, quantity, minOrder, maxOrder, overrideDates, multipleScan, audience, accessCode } = state;
            const price = free ? 0 : state.price;
            
            // POS field values can be null. The online ticket values will be used as a fallback
            const { posVisible, posFree } = state;
            let posPrice = posFree ? 0 : state.posPrice;
            if (posPrice === '') posPrice = null;
            let posName = state.posName.trim() || null;

            // Convert date objects to datetime strings
            // Start dates are rounded down to the nearest minute; end dates are rounded up to the nearest minute
            const startDateString = getMomentFromDateAndTime(startDate, startTime).format("YYYY-MM-DD HH:mm:00");
            const endDateString = getMomentFromDateAndTime(endDate, endTime).format("YYYY-MM-DD HH:mm:59");
            
            const saleStartDateString = getMomentFromDateAndTime(saleStartDate, saleStartTime).format("YYYY-MM-DD HH:mm:00");
            const saleEndDateString = getMomentFromDateAndTime(saleEndDate, saleEndTime).format("YYYY-MM-DD HH:mm:59");

            const formData = {
                name,
                price,
                type,
                description,
                startDate: startDateString,
                endDate: endDateString,
                saleStartDate: saleStartDateString,
                saleEndDate: saleEndDateString,
                quantity,
                minOrder,
                maxOrder,
                overrideDates,
                multipleScan,
                publish: audience === 'visible' || audience === 'accessCode',
                accessCode: accessCode || null, // If access code is not set, it is null in the DB
                posVisible: posVisible === 'visible',
                posName,
                posPrice
            }

            // Edit Mode
            if (editMode) {
                if (image?.includes('https://')) {
                    // Preview image hasn't changed. Don't update sponsorImage
                } else {
                    formData.sponsorImage = image;
                }
            // Create Mode
            } else { 
                formData.sponsorImage = image;
            }

            onSubmit(formData);
        } else {
            notify.warning('Please make sure every field is filled in correctly')
        }
    }


    function handleSwitchChange(e) {
        if (e.target.id === 'overrideDates' && e.target.checked === false) {
            setState({
                ...state,
                [e.target.id]: e.target.checked,
                startDate: defaultValues?.eventStartDate ?? null,
                startTime: defaultValues?.eventStartDate ?? null,
                endDate: defaultValues?.eventEndDate ?? null,
                endTime: defaultValues?.eventEndDate ?? null,
            })
        }
        else {
            setState({ ...state, [e.target.id]: e.target.checked })
        }
    }


    function handleTextChange(e) {
        let charLimit = 1000;
        if (e.target.id === 'name') charLimit = 100;
        if (e.target.id === 'posName') charLimit = 50;

        const value = e.target.value.slice(0, charLimit);
        setState({ ...state, [e.target.id]: value });
    }

    function handlePriceChange(e) {
        let value = e.target.value.replace(/[^0-9.]/g, '');

        // Add 0 if there is nothing before the decimal point
        if (value.includes('.') && value.split('.')[0] === '') {
            value = '0' + value;
        }
        // Check if value is a number. Remove extra digits after decimal point
        if (!isNaN(value) || value === '') {
            if (value.includes('.') && value.split('.')[1].length > 2) {
                value = value.slice(0, value.indexOf('.') + 3);
            }
            setState({ ...state, [e.target.id]: value });
        }
    }

    function handleIntChange(e) {
        const value = e.target.value.replace(/[^0-9]/g, '');
        setState({ ...state, [e.target.id]: value });
    }


    function handleDateChange(id, value) {
        setState({ ...state, [id]: moment(value) });
    }

    function handleAudienceChange(e) {
        const value = e.target.value;
        
        // We also clear access code form state whenever we change the audience value
        setState({ ...state, audience: value, accessCode: '' });
        setInvalidFields(i => ({ ...i, accessCode: false }));
    }
    
    function handlePOSVisibilityChange(e) {
        setState({ ...state, posVisible: e.target.value });
    }

    function handleAccessCodeChange(e) {
        const value = e.target.value.replace(/[^0-9a-zA-Z]/g, '').slice(0,25);
        setState({ ...state, [e.target.id]: value });
    }


    function validateFields() {
        let errors = {};

        // Check for empty form elements
        ['name', 'description', 'quantity', 'minOrder', 'maxOrder'].forEach((field) => {
            errors[field] = (String(state[field]).trim() === '') ? true : false;
        })

        // Check for empty access code when feature is enabled
        if (state.audience === 'accessCode') {
            errors.accessCode = (String(state.accessCode).trim() === '') ? true : false;
        }

        // Check price if product isn't free
        errors.price = (!state.free && state.price === '') ? true : false;

        // Check for valid start and end dates
        ['startDate', 'startTime', 'endDate', 'endTime', 'saleStartDate', 'saleStartTime', 'saleEndDate', 'saleEndTime'].forEach((field) => {
            // Invalid dates are still of type Object. == is needed to compare them to the string "Invalid Date"
            // eslint-disable-next-line eqeqeq
            errors[field] = ((state[field] === null) || (state[field].isValid() === false)) ? true : false;
        })

        // Check if minimum order is less than one
        if (!errors.minOrder) {
            errors.minLessThanOne = (Number(state.minOrder) < 1) ? true : false;
        } else {
            errors.minLessThanOne = false;
        }

        // Check if minimun order is smaller than quantity - only check if quantity and minOrder are valid
        if (!errors.quantity && !errors.minOrder) {
            errors.minOrderOverQty = (Number(state.minOrder) > Number(state.quantity)) ? true : false;
        } else {
            errors.minOrderOverQty = false;
        }

        // Check if minimum order is smaller than number of products - only check if min and max orders are valid
        if (!errors.minOrder && !errors.maxOrder) {
            errors.minOrderOverMax = (Number(state.minOrder) > Number(state.maxOrder)) ? true : false;
        } else {
            errors.minOrderOverMax = false;
        }

        // Check if scan end date is after scan start date
        if (!errors.startDate && !errors.endDate && !errors.startTime && !errors.endTime) {
            const fullStartDate = getMomentFromDateAndTime(state.startDate, state.startTime);
            const fullEndDate = getMomentFromDateAndTime(state.endDate, state.endTime);
            errors.scanRange = (fullStartDate > fullEndDate) ? true : false;
        } else {
            errors.scanRange = false;
        }

        if (!errors.saleStartDate && !errors.saleEndDate && !errors.saleStartTime && !errors.saleEndTime) {
            const fullStartDate = getMomentFromDateAndTime(state.saleStartDate, state.saleStartTime);
            const fullEndDate = getMomentFromDateAndTime(state.saleEndDate, state.saleEndTime);
            errors.saleRange = (fullStartDate > fullEndDate) ? true : false;
        } else {
            errors.saleRange = false;
        }

        // Update
        setInvalidFields({ ...invalidFields, ...errors });

        // Return true if one or more errors were set to true
        return !Object.values(errors).some((value) => value);
    }

    return (
        <Root>

            <Box display='flex' justifyContent={'center'}>
                <TabContainerWrapper>
                    <TabContainer>
                        <Tabs variant='fullWidth' value={tab} onChange={(_, value) => setTab(value)}>
                            <TabItem label='Online' />
                            <TabItem label='In-Person' />
                        </Tabs>
                    </TabContainer>
                </TabContainerWrapper>
            </Box>

            {tab === 0 && (
                <Grid container spacing={4}>

                    <Grid item xs={12} md={8}>

                            <Grid container spacing={4}>

                                <Grid item xs={12}>
                                    <Section>
                                        <Header>About</Header>
                                        <Typography>Provide a concise name and description, helping attendees understand the purpose and details of the ticket they're purchasing.</Typography>
                                        <Content>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <TextField id='name' autoFocus={autoFocus} variant='outlined' fullWidth label='Ticket Name' value={state.name} error={invalidFields.name} onChange={handleTextChange} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextArea id='description' maxLength={1000} variant='outlined' minRows={5} maxRows={5} label='Description' value={state.description} error={invalidFields.description} onChange={handleTextChange} />
                                                </Grid>
                                            </Grid>
                                        </Content>
                                    </Section>
                                </Grid>

                                <Grid item xs={12}>
                                    <Section>
                                        <Header>Price</Header>
                                        <Typography>Set the cost of your ticket for online purchases. If your event is free to attend, simply toggle the 'Free' switch.</Typography>
                                        <Content>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6} md={4}>
                                                    <TextField
                                                        id='price'
                                                        fullWidth
                                                        variant='outlined'
                                                        label='Online Price'
                                                        value={state.free ? 'Free' : state.price}
                                                        error={invalidFields.price && !state.free}
                                                        onChange={handlePriceChange}
                                                        disabled={state.free}
                                                        InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }} />
                                                </Grid>
                                                <Grid item xs={6} md={8} style={{ display: 'flex', alignContent: 'center' }}>
                                                    <FormControlLabel control={<Switch id='free' checked={state.free} onChange={handleSwitchChange} />} label="Free" />
                                                </Grid>
                                            </Grid>
                                        </Content>
                                    </Section>
                                </Grid>

                                <Grid item xs={12}>
                                    <Section>
                                        <Header>Available Quantity</Header>
                                        <Typography>Specify the maximum number of tickets available for this ticket type. Additionally, set the minimum and maximum limits for the number of tickets that can be purchased per transaction.</Typography>
                                        <Content>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6} md={4}>
                                                    <TextField fullWidth id='quantity' variant='outlined' label='Qty Available' value={state.quantity} error={invalidFields.quantity} onChange={handleIntChange} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6} md={4}>
                                                            <TextField fullWidth id='minOrder' variant='outlined' label='Order Minimum' value={state.minOrder} error={invalidFields.minOrder || invalidFields.minOrderOverQty || invalidFields.minLessThanOne} onChange={handleIntChange} />
                                                        </Grid>
                                                        <Grid item xs={6} md={4}>
                                                            <TextField fullWidth id='maxOrder' variant='outlined' label='Order Maximum' value={state.maxOrder} error={invalidFields.maxOrder || invalidFields.minOrderOverMax} onChange={handleIntChange} />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {invalidFields.minLessThanOne  && <Typography color='error'>Minimum order must be more than zero</Typography>}
                                            {invalidFields.minOrderOverMax && <Typography color='error'>Minimum order cannot be higher than maximum order</Typography>}
                                            {invalidFields.minOrderOverQty && <Typography color='error'>Minimum order cannot be higher than total available tickets</Typography>}
                                        </Content>
                                    </Section>
                                </Grid>

                                <Grid item xs={12}>
                                    <Section>
                                        <Header>Ticket Visibility</Header>
                                        <Typography gutterBottom>Select who can view and purchase this ticket online. Choose 'Available to Everyone' for public access, 'Not Available' to hide the ticket, or 'Access Code Required' to restrict access to ticket purchasers with a unique code.</Typography>
                                        <RadioGroup id='audience' aria-label="audience" value={state.audience} onChange={handleAudienceChange}>
                                            <FormControlLabel value="visible" control={<Radio />} label="Available to Everyone" />
                                            <FormControlLabel value="hidden" control={<Radio />} label="Not Available to Anyone" />
                                            <FormControlLabel value="accessCode" control={<Radio />} label="Access Code Required" />
                                        </RadioGroup>
                                        {state.audience === 'accessCode' && (
                                            <AccessCodeContainer>
                                                <Typography variant='caption'>code has to be entered on the event page to view this ticket (max 25 characters)</Typography>
                                                <AccessCodeInput id='accessCode' variant='outlined' placeholder='Access Code' value={state.accessCode} error={invalidFields.accessCode} onChange={handleAccessCodeChange} />
                                            </AccessCodeContainer>
                                        )}
                                    </Section>
                                </Grid>
                                
                                <Grid item xs={12}>
                                    <Section>
                                        <Header>When is this Ticket Available for Purchase</Header>
                                        <Typography>Define the start and end dates and times during which the ticket will be available for purchase online.</Typography>
                                        <Box mt={6}>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <Box mr={[0, '1rem']} mb={4}>
                                                        <DatePicker
                                                            label="Start Date"
                                                            value={state.saleStartDate}
                                                            onChange={value => handleDateChange('saleStartDate', value)}
                                                            error={invalidFields.saleStartDate || invalidFields.saleRange}
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Box ml={[0, '1rem']} mb={4}>
                                                        <TimePicker
                                                            label="Start Time"
                                                            value={state.saleStartTime}
                                                            onChange={value => handleDateChange('saleStartTime', value)}
                                                            error={invalidFields.saleStartTime || invalidFields.saleRange}
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Box mr={[0, '1rem']}>
                                                        <DatePicker
                                                            label="End Date"
                                                            value={state.saleEndDate}
                                                            onChange={value => handleDateChange('saleEndDate', value)}
                                                            error={invalidFields.saleEndDate || invalidFields.saleRange}
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Box ml={[0, '1rem']}>
                                                        <TimePicker
                                                            label="End Time"
                                                            value={state.saleEndTime}
                                                            onChange={value => handleDateChange('saleEndTime', value)}
                                                            error={invalidFields.saleEndTime || invalidFields.saleRange}
                                                        />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            {invalidFields.saleRange && <Typography color='error'>End date cannot come before start date</Typography>}
                                            <DateSummary
                                                startDate={state.saleStartDate}
                                                startTime={state.saleStartTime}
                                                endDate={state.saleEndDate}
                                                endTime={state.saleEndTime}
                                            />
                                        </Box>
                                    </Section>
                                </Grid>

                                <Grid item xs={12}>
                                    <Section>
                                        <Header>When can this Ticket be Scanned</Header>
                                        <Typography>Customize when the ticket will be scanned for admission into the event. The ticket will be considered invalid outside of this timeframe.</Typography>
                                        <Content>

                                            <Box display='flex' flexDirection='column' mb={4} alignItems='flex-start'>
                                                <FormControlLabel control={<Switch id='overrideDates' checked={state.overrideDates} onChange={handleSwitchChange} />} label="Limit Check-in Time" />
                                                <SwitchSubHeader>Enable this feature if you'd like to set specific days and times for when this ticket type can be scanned for admission at the event.</SwitchSubHeader>
                                            </Box>

                                            <Box mb={4} mt={2}>
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <Box mr={[0, '1rem']} mb={4}>
                                                            <DatePicker
                                                                disabled={!state.overrideDates}
                                                                label="Start Date"
                                                                value={state.startDate}
                                                                onChange={value => handleDateChange('startDate', value)}
                                                                error={invalidFields.startDate || invalidFields.scanRange}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Box ml={[0, '1rem']} mb={4}>
                                                            <TimePicker
                                                                disabled={!state.overrideDates}
                                                                label="Start Time"
                                                                value={state.startTime}
                                                                onChange={value => handleDateChange('startTime', value)}
                                                                error={invalidFields.startTime || invalidFields.scanRange}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Box mr={[0, '1rem']}>
                                                            <DatePicker
                                                                disabled={!state.overrideDates}
                                                                label="End Date"
                                                                value={state.endDate}
                                                                onChange={value => handleDateChange('endDate', value)}
                                                                error={invalidFields.endDate || invalidFields.scanRange}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Box ml={[0, '1rem']}>
                                                            <TimePicker
                                                                disabled={!state.overrideDates}
                                                                label="End Time"
                                                                value={state.endTime}
                                                                onChange={value => handleDateChange('endTime', value)}
                                                                error={invalidFields.endTime || invalidFields.scanRange}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                {invalidFields.scanRange && <Typography color='error'>End date cannot come before start date</Typography>}
                                            </Box>

                                            <Box display='flex' flexDirection='column' mb={4} mt={4} alignItems='flex-start'>
                                                <FormControlLabel control={<Switch id='multipleScan' checked={state.multipleScan} onChange={handleSwitchChange} />} label="Allow Multi-day Check-in" />
                                                <SwitchSubHeader>Turn this feature on to allow this ticket to be checked-in once per calendar day for the duration of your event.</SwitchSubHeader>
                                            </Box>
                                            <ScanDateSummary
                                                startDate={state.startDate}
                                                startTime={state.startTime}
                                                endDate={state.endDate}
                                                endTime={state.endTime}
                                                multiCheckins={state.multipleScan}
                                            />
                                        </Content>
                                    </Section>
                                </Grid>

                                <Grid item xs={12}>
                                    <Section>
                                        <Header>Ticket Sponsorship Image</Header>
                                        <Typography>Upload an image to appear in the bottom left corner of the PDF version of this ticket, which can be used to showcase a sponsor or logo.</Typography>
                                        <Typography variant='subtitle2'>{'* Type: PNG, JPG. File Size < 1MB. Recommended Dimensions: 800x400, 1000x500'}</Typography>
                                        <Content mb={2}>
                                            {enableSponsor ? (
                                                <SponsorImagePicker
                                                    value={image}
                                                    onChange={setImage}
                                                />
                                            ) : (<>
                                                <FormControlLabel disabled control={<Switch checked={false} />} label='Enable' />
                                                <Typography style={{fontStyle: 'italic'}}>*Speak with your FrontDoor+ rep to have this feature enabled.</Typography>
                                            </>)}
                                        </Content>
                                    </Section>
                                </Grid>

                            </Grid>

                    </Grid>
                    
                    <Grid item xs={12} md={4}>
                        <Section>
                            <ReceiptPreview title='Online Pricing' hostId={hostId} eventId={eventId} price={state.price} name={state.name} />
                        </Section>
                    </Grid>

                </Grid>
            )}

            {tab === 1 && (<>
                <Box mb={4}>
                    <Alert severity='info'>
                    The <Bold variant='inherit'>FrontDoor+ | Point-of-Sale (POS)</Bold> app is used to accept debit & credit payments as well as track cash.  You can customize the POS details below.
                    </Alert>
                </Box>

                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Section>
                                    <Header>POS Ticket Visibility</Header>
                                    <Typography gutterBottom>Specify whether this ticket will be displayed and available for sale within the <Bold>FrontDoor+ | Point-of-Sale (POS)</Bold> app.</Typography>
                                    <Content>
                                        <RadioGroup id='posVisible' aria-label="POS Visibility" value={state.posVisible} onChange={handlePOSVisibilityChange}>
                                            <FormControlLabel value="visible" control={<Radio />} label={<Typography><Bold>Yes</Bold> - make this ticket visible in the POS app for in-person purchases</Typography>} />
                                            <FormControlLabel value="hidden" control={<Radio />} label={<Typography><Bold>No</Bold> - don't make this ticket visible in the POS app for in-person purchases</Typography>} />
                                        </RadioGroup>
                                    </Content>
                                </Section>
                            </Grid>

                            <Grid item xs={12}>
                                <Section>
                                    <Header>Nickname</Header>
                                    <Typography>Provide a shortened or alternate name for this ticket. This will be displayed in the <Bold>FrontDoor+ | Point-of-Sale (POS)</Bold> app during in-person transactions. By default, the online Ticket Name is used.</Typography>
                                    <Content>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6} >
                                                <TextField id='posName' autoFocus={autoFocus} variant='outlined' fullWidth label='Nickname' value={state.posName} onChange={handleTextChange} />
                                            </Grid>
                                        </Grid>
                                    </Content>
                                </Section>
                            </Grid>

                            <Grid item xs={12}>
                                <Section>
                                    <Header>Price</Header>
                                    <Typography>Override the ticket price specifically for point-of-sale transactions. By default, the online price is utilized, but here you can adjust it as needed for in-person purchases.</Typography>
                                    <Content>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <TextField
                                                    id='posPrice'
                                                    fullWidth
                                                    variant='outlined'
                                                    label='In-Person Price'
                                                    value={state.posFree ? 'Free' : state.posPrice}
                                                    onChange={handlePriceChange}
                                                    disabled={state.posFree}
                                                    InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }} />
                                            </Grid>
                                            <Grid item xs={6} style={{ display: 'flex', alignContent: 'center' }}>
                                                <FormControlLabel control={<Switch id='posFree' checked={state.posFree} onChange={handleSwitchChange} />} label="Free" />
                                            </Grid>
                                        </Grid>
                                    </Content>
                                </Section>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Section>
                            <ReceiptPreview pos title='In-Person Pricing' hostId={hostId} eventId={eventId} price={state.posPrice || state.price} name={state.name} />
                        </Section>
                    </Grid>

                </Grid>

                <Box mt={4}>
                    <Alert severity='info'>
                        Reach out to us if you'd like to learn more about our in-person payment and cash tracking options.
                    </Alert>
                </Box>
            </>)}

            <Grid container spacing={4}>
                <Grid item xs={12} md={8}>
                    <Box display='flex' justifyContent='flex-end' mb={4} mt={4}>
                        {editMode && (
                            <>
                                {deletable ? (
                                    <DeleteButton onClick={onDelete} disabled={loading}>Delete</DeleteButton>
                                ) : (
                                    <Tooltip message='Tickets and add-ons cannot be deleted after being purchased on the store, or included in a bundle.'>
                                        <DeleteButtonDisabled>Delete</DeleteButtonDisabled>
                                    </Tooltip>
                                )}
                            </>
                        )}
                        <CancelButton onClick={onCancel} disabled={loading}>Cancel</CancelButton>
                        <SubmitButton onClick={handleSubmit} disabled={loading}>Save</SubmitButton>
                    </Box>


                    {createdAtMsg && (
                        <Typography display='block' align='right' variant='caption'>{createdAtMsg}</Typography>
                    )}
                    
                    {updatedAtMsg && (
                        <Typography display='block' align='right' variant='caption'>{updatedAtMsg}</Typography>
                    )}
                </Grid>

            </Grid>
        </Root>
    )
}

function DateSummary(props) {
    const { startDate, startTime, endDate, endTime } = props;

    if (
        moment(startDate).isValid() === false ||
        moment(endDate).isValid()   === false ||
        moment(startTime).isValid() === false ||
        moment(endTime).isValid()   === false
    ) {
        return null;
    }

    const start = getMomentFromDateAndTime(startDate, startTime);
    const end = getMomentFromDateAndTime(endDate, endTime);
    const text = `${start.format('dddd, MMM Do @ h:mma')} and ${end.format('dddd, MMM Do @ h:mma')}`;

    return (
        <Box my={2}>
            <DateSummaryText>This ticket can be purchased between:</DateSummaryText>
            {start > end
                ? <DateSummaryTextError>{text}</DateSummaryTextError>
                : <DateSummaryTextBold>{text}</DateSummaryTextBold>
            }
        </Box>
    )

}

function ScanDateSummary(props) {
    const { startDate, startTime, endDate, endTime, multiCheckins } = props;

    if (
        moment(startDate).isValid() === false ||
        moment(endDate).isValid()   === false ||
        moment(startTime).isValid() === false ||
        moment(endTime).isValid()   === false
    ) {
        return null;
    }

    const start = getMomentFromDateAndTime(startDate, startTime);
    const end = getMomentFromDateAndTime(endDate, endTime);

    const singleDay = start.format('YYYY-MM-DD') === end.format('YYYY-MM-DD');

    const text = singleDay
        ? `${start.format('dddd MMM Do,')} ${start.format('h:mma')} to ${end.format('h:mma')}`
        : `${start.format('dddd, MMM Do @ h:mma')} and ${end.format('dddd, MMM Do @ h:mma')}`;

    return (
        <Box my={2}>
            <DateSummaryText display='inline'>This ticket can be used </DateSummaryText>
            {start.format('YYYY-MM-DD') === end.format('YYYY-MM-DD') ? (<>
                <DateSummaryTextBold display='inline'>ONCE </DateSummaryTextBold>
                <DateSummaryText display='inline'>on:</DateSummaryText>
            </>) : (<>
                <DateSummaryTextBold display='inline'>{multiCheckins ? 'ONCE PER DAY' : 'ONCE'} </DateSummaryTextBold>
                <DateSummaryText display='inline'>between:</DateSummaryText>
            </>)}

            {start > end
                ? <DateSummaryTextError>{text}</DateSummaryTextError>
                : <DateSummaryTextBold>{text}</DateSummaryTextBold>
            }
        </Box>
    )

}

const TabItem = withStyles({
    selected: {
        fontWeight: 'bold'
    },
})(Tab);



const Root = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4),
        maxWidth: theme.breakpoints.values.lg,
        marginRight: 'auto',
        marginLeft: 'auto'
    },
}));

const TabContainerWrapper = styled(Box)(({ theme }) => ({
    borderRadius: 12,
    overflow: 'hidden',
    marginBottom: theme.spacing(4)
}));

const TabContainer = styled(Box)(({ theme }) => ({
    width: 400,
    backgroundColor: 'white',
}));

const SwitchSubHeader = styled(Typography)(({ theme }) => ({
    marginLeft: 48,
    marginTop: -6,
    marginBottom: 8
}));
SwitchSubHeader.defaultProps = { variant: 'caption' };

const SwitchTextField = styled(TextField)(({ theme }) => ({
    marginLeft: 48,
    maxWidth: 400
}));
SwitchTextField.defaultProps = { size: 'small' };

const DeleteButtonDisabled = styled(Button)(({ theme }) => ({
    color: theme.palette.grey[400],
    borderColor: theme.palette.grey[400],
    marginRight: 'auto',
    '&:hover': {
        cursor: 'default',
        backgroundColor: 'transparent'
    }
}));
DeleteButtonDisabled.defaultProps = { variant: 'outlined', disableRipple: true }


const AccessCodeContainer = styled(Box)(({ theme }) => ({
    marginLeft: 32,
    display: 'flex',
    flexDirection: 'column'
}));

const AccessCodeInput = styled(TextField)(({ theme }) => ({
    maxWidth: 400,
    marginTop: theme.spacing(1)
}));
AccessCodeInput.defaultProps = { size: 'small' };

const DatePicker = styled(KeyboardDatePicker)(({ theme }) => ({}));
DatePicker.defaultProps = {
    autoOk: true, 
    fullWidth: true,
    inputVariant: "outlined",
    format: "MM/dd/yyyy"
}

const TimePicker = styled(KeyboardTimePicker)(({ theme }) => ({}));
TimePicker.defaultProps = {
    fullWidth: true,
    inputVariant: "outlined",
    mask: "__:__ _M"
}


const DateSummaryText = styled(Typography)(({ theme }) => ({
    color: theme.palette.info.dark
}));

const DateSummaryTextBold = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    color: theme.palette.info.dark
}));

const DateSummaryTextError = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    color: theme.palette.error.main
}));

const Bold = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    display: 'inline'
}));