import React, { useState } from 'react';

// Material-UI
import { makeStyles, useTheme, styled } from '@material-ui/core/styles';
import { Box, Button, Card, CardHeader, CardContent, CardActions, CircularProgress, Typography, TextField, Paper } from '@material-ui/core';
import LanguageIcon from '@material-ui/icons/Language';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

// Components
import { ActionButton } from 'components/ui/buttons';
import InfoToolTip from 'components/popups/InfoTooltip';
import FullScreenModal from 'components/popups/FullScreenModal';
import { useNotification } from 'context/notification';
import useExpresso from 'hooks/useExpresso';
import fetchExpresso from 'utility/fetchExpresso';
import { validateURL } from 'utility/validation';


export default function SocialMediaLinks(props) {
    const [refresh, setRefresh] = useState(0);
    const [host, loadingHost, error]  = useExpresso(`/apiv2/hosts/${props.hostId}`, 'GET', null, null, [refresh]);

    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const c = useStyles();
    const { notify } = useNotification();
    
    // RETURN: Loading
    if (loadingHost) {
        return (
            <Card style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <CardHeader title='Social Media Links' />
            <Box flexGrow={1}>
                <Box display='flex' height='100%' width='100%' alignItems='center' justifyContent='center'>
                    <CircularProgress />
                </Box>
            </Box>
        </Card>
        );
    }

    // RETURN: Error
    if (error || !host) {
        return (
            <Card className={c.card}>
                <CardHeader title='Social Media Links' />
                <Box flexGrow={1}>
                    <Box display='flex' height='100%' width='100%' alignItems='center' justifyContent='center'>
                        <p>There was a problem getting your social media links</p>
                    </Box>
                </Box>
            </Card>
        )
    }


    const { url_website, url_facebook, url_twitter, url_instagram, url_tiktok, url_linkedin } = host;


    const handleModalClose = () => {
        setModalOpen(false);
    }

    const handleUpdate = (website, facebook, twitter, instagram, tiktok, linkedin) => {
        if (loading) return;
        setLoading(true);


        fetchExpresso(`/apiv2/hosts/${props.hostId}/socials`, {
            method: 'PUT',
            body: {
                website: website.trim() || '',
                facebook: facebook.trim() || '',
                twitter: twitter.trim() || '',
                instagram: instagram.trim() || '',
                tiktok: tiktok.trim() || '',
                linkedin: linkedin.trim() || '',
            }
        })
            .then(res => {
                if (res.status === 200) {
                    setModalOpen(false);
                    setLoading(false);
                    setRefresh(refresh + 1);
                } else {
                    throw new Error();
                }
            })
            .catch(e => {
                notify.error('There was a problem updating your socials');
                setLoading(false);
            })
    }

    return (
        <Card className={c.card} >
            <CardHeader
                title='Social Media Links'
                classes={{ action: c.cardAction }}
                action={<InfoToolTip message="These links are displayed on your public Event Listings and Event Ticket pages." />}
            />

            <CardContent className={c.body}>

                <Box display='flex' alignItems='center'>
                    <LanguageIcon className={url_website ? c.iconWebsite : c.iconDisabled} />
                    {url_website
                        ? <a className={c.link} href={url_website} target="_blank" rel='noopener noreferrer'><Typography>{url_website}</Typography></a>
                        : <Typography variant='caption'>Not set</Typography>
                    }
                    </Box>

                <Box display='flex' alignItems='center'>
                    <FacebookIcon className={url_facebook ? c.iconFacebook : c.iconDisabled} />
                    {url_facebook
                        ? <a className={c.link} href={url_facebook} target="_blank" rel='noopener noreferrer'><Typography>{url_facebook}</Typography></a>
                        : <Typography variant='caption'>Not set</Typography>
                    }
                </Box>

                <Box display='flex' alignItems='center'>
                    <TwitterIcon className={url_twitter ? c.iconTwitter : c.iconDisabled} />
                    {url_twitter
                        ? <a className={c.link} href={url_twitter} target="_blank" rel='noopener noreferrer'><Typography>{url_twitter}</Typography></a>
                        : <Typography variant='caption'>Not set</Typography>
                    }
                </Box>

                <Box display='flex' alignItems='center'>
                    <InstagramIcon className={url_instagram ? c.iconInstagram : c.iconDisabled} />
                    {url_instagram
                        ? <a className={c.link} href={url_instagram} target="_blank" rel='noopener noreferrer'><Typography>{url_instagram}</Typography></a>
                        : <Typography variant='caption'>Not set</Typography>
                    }
                </Box>

                <Box display='flex' alignItems='center'>
                    <img alt='' src={url_tiktok ? process.env.REACT_APP_PORTAL_S3_LOCATION + '/tiktok-icon-black.svg' : process.env.REACT_APP_PORTAL_S3_LOCATION + '/tiktok-icon-grey.svg'} className={c.iconTiktok} />
                    {url_tiktok
                        ? <a className={c.link} href={url_tiktok} target="_blank" rel='noopener noreferrer'><Typography>{url_tiktok}</Typography></a>
                        : <Typography variant='caption'>Not set</Typography>
                    }
                </Box>
                
                <Box display='flex' alignItems='center'>
                    <LinkedInIcon className={url_linkedin ? c.iconLinkedin : c.iconDisabled} />
                    {url_linkedin
                        ? <a className={c.link} href={url_linkedin} target="_blank" rel='noopener noreferrer'><Typography>{url_linkedin}</Typography></a>
                        : <Typography variant='caption'>Not set</Typography>
                    }
                </Box>

            </CardContent>

            <CardActions>
                <ActionButton onClick={() => setModalOpen(true)}>Edit</ActionButton>
            </CardActions>

            <FullScreenModal open={modalOpen} onClose={handleModalClose} title='Update Socials'>
                <SocialLinkForm
                    onUpdate={handleUpdate}
                    onCancel={handleModalClose}
                    website={url_website}
                    facebook={url_facebook}
                    twitter={url_twitter}
                    instagram={url_instagram}
                    tiktok={url_tiktok}
                    linkedin={url_linkedin}
                    loading={loading}
                />
            </FullScreenModal>
        </Card>
    )
}



function SocialLinkForm(props) {
    // Props
    const { onUpdate, onCancel, loading, hostId } = props;

    // State - Form Values
    const [website, setWebsite] = useState(props.website ||'');
    const [facebook, setFacebook] = useState(props.facebook ||'');
    const [twitter, setTwitter] = useState(props.twitter ||'');
    const [instagram, setInstagram] = useState(props.instagram ||'');
    const [tiktok, setTiktok] = useState(props.tiktok ||'');
    const [linkedin, setLinkedin] = useState(props.linkedin ||'');

    // Error States
    const [websiteError, setWebsiteError] = useState(false);
    const [facebookError, setFacebookError] = useState(false);
    const [twitterError, setTwitterError] = useState(false);
    const [instagramError, setInstagramError] = useState(false);
    const [tiktokError, setTiktokError] = useState(false);
    const [linkedinError, setLinkedinError] = useState(false);

    // Hooks
    const theme = useTheme();
    const classes = useStyles();

    function handleSubmit() {
        let error = false;

        setWebsiteError(false);
        setFacebookError(false);
        setTwitterError(false);
        setInstagramError(false);
        setTiktokError(false);
        setLinkedinError(false);

        if (validateURL(website) === false && website.trim() !== '') {
            setWebsiteError(true);
            error = true;
        }
        if (validateURL(facebook) === false && facebook.trim() !== '') {
            setFacebookError(true);
            error = true;
        }
        if (validateURL(twitter) === false && twitter.trim() !== '') {
            setTwitterError(true);
            error = true;
        }
        if (validateURL(instagram) === false && instagram.trim() !== '') {
            setInstagramError(true);
            error = true;
        }
        if (validateURL(tiktok) === false && tiktok.trim() !== '') {
            setTiktokError(true);
            error = true;
        }
        if (validateURL(linkedin) === false && linkedin.trim() !== '') {
            setLinkedinError(true);
            error = true;
        }

        if (error) return;

        onUpdate(website.trim(), facebook.trim(), twitter.trim(), instagram.trim(), tiktok.trim(), linkedin.trim());
    }

    return (
        <Box width={1} display='flex' flexDirection='column' alignItems='center'>
            <Box width={[0.9, 0.9, theme.breakpoints.values.md - 60]} my={6} display='flex' justifyContent={hostId ? 'space-between' : 'center'} flexWrap='wrap'>

                <Box width={[1, 1, 0.6]} display='flex' flexDirection='column'>

                    <FormContainer>

                        <TextField
                            type='url'
                            variant='outlined'
                            margin='normal'
                            label='Website URL'
                            placeholder='https://example.com'
                            error={websiteError}
                            helperText={websiteError ? 'Make sure your link is properly formatted. Example: https://example.com ' : undefined}
                            value={website}
                            onChange={e => setWebsite(e.target.value)}
                        />

                        <TextField
                            type='url'
                            variant='outlined'
                            margin='normal'
                            label='Facebook URL'
                            placeholder='https://facebook.com/example'
                            error={facebookError}
                            helperText={facebookError ? 'Make sure your link is properly formatted. Example: https://facebook.com/example' : undefined}
                            value={facebook}
                            onChange={e => setFacebook(e.target.value)}
                        />

                        <TextField
                            type='url'
                            variant='outlined'
                            margin='normal'
                            label='Twitter URL'
                            placeholder='https://twitter.com/example'
                            error={twitterError}
                            helperText={twitterError ? 'Make sure your link is properly formatted. Example: https://twitter.com/example' : undefined}
                            value={twitter}
                            onChange={e => setTwitter(e.target.value)}
                        />

                        <TextField
                            type='url'
                            variant='outlined'
                            margin='normal'
                            label='Instagram URL'
                            placeholder='https://instagram.com/example'
                            error={instagramError}
                            helperText={instagramError ? 'Make sure your link is properly formatted. Example: https://instagram.com/example' : undefined}
                            value={instagram}
                            onChange={e => setInstagram(e.target.value)}
                        />

                        <TextField
                            type='url'
                            variant='outlined'
                            margin='normal'
                            label='Tiktok URL'
                            placeholder='https://tiktok.com/@example'
                            error={tiktokError}
                            helperText={tiktokError ? 'Make sure your link is properly formatted. Example: https://tiktok.com/@example' : undefined}
                            value={tiktok}
                            onChange={e => setTiktok(e.target.value)}
                        />

                        <TextField
                            type='url'
                            variant='outlined'
                            margin='normal'
                            label='LinkedIn URL'
                            placeholder='https://linkedin.com/company/example'
                            error={linkedinError}
                            helperText={linkedinError ? 'Make sure your link is properly formatted. Example: https://linkedin.com/company/example' : undefined}
                            value={linkedin}
                            onChange={e => setLinkedin(e.target.value)}
                        />

                        <Box display='flex' justifyContent='flex-end' mb={2} mt={2}>
                            <Button variant='outlined' className={classes.cancelButton} onClick={onCancel}>Cancel</Button>
                            <Button variant='outlined' className={classes.saveButton} onClick={handleSubmit} disabled={loading}>Save</Button>
                        </Box>

                    </FormContainer>

                </Box>

            </Box>
        </Box>
    )
}

const FormContainer = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2)
}));

const useStyles = makeStyles(theme => ({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    body: {
        flex: 1
    },
    cardAction: {
        marginTop: 0,
        marginRight: 0
    },
    iconWebsite: {
        fontSize: 20,
        paddingRight: 10,
        paddingTop: 5,
        paddingBottom: 5
    },
    iconFacebook: {
        fontSize: 20,
        paddingRight: 10,
        paddingTop: 5,
        paddingBottom: 5,
        color: '#3b5998'
    },
    iconTwitter: {
        fontSize: 20,
        paddingRight: 10,
        paddingTop: 5,
        paddingBottom: 5,
        color: '#55acee'
    },
    iconInstagram: {
        fontSize: 20,
        paddingRight: 10,
        paddingTop: 5,
        paddingBottom: 5,
        color: '#E4405F'
    },
    iconTiktok: {
        fontSize: 20,
        paddingRight: 10,
        paddingTop: 5,
        paddingBottom: 5,
        height: 20
    },
    iconLinkedin: {
        fontSize: 20,
        paddingRight: 10,
        paddingTop: 5,
        paddingBottom: 5,
        color: 'rgb(51,117,178)'
    },
    iconDisabled: {
        fontSize: 20,
        color: theme.palette.grey[500],
        paddingRight: 10,
        paddingTop: 5,
        paddingBottom: 5
    },
    link: {
        wordWrap: 'break-word',
        color: '#000'
    },
    updateButton: {
        color: 'white',
        backgroundColor: theme.palette.primary.main
    },
    saveButton: {
        color: theme.palette.success.main,
        borderColor: theme.palette.success.main
    },
    cancelButton: {
        marginRight: '1rem'
    }
}))