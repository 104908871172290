export const FieldTypes = {
    'SHORT_ANSWER': 1,
    'PARAGRAPH': 2,
    'SINGLE_CHOICE': 3,
    'MULTIPLE_CHOICE': 4,
    'DROPDOWN': 5,
    'FULL_DATE': 6,
    'AGE': 7,
    'YES_OR_NO': 8,
    'SHORT_DATE': 9,
    'TERMS_AND_CONDITIONS': 10,
    'TEXT_BLOCK': 999,
    'IMAGE_BLOCK': 998
}