import React, { useState, useEffect } from 'react';

// Material-UI
import { styled } from '@material-ui/core/styles';
import { Box, TextField, Button, Grid, Typography, CircularProgress, FormControlLabel, Checkbox } from '@material-ui/core';
import { Card, CardHeader, CardContent, CardActions } from '@material-ui/core';
import { Table, TableBody, TableRow, TableCell as _TableCell, TableHead, TableContainer } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

// Material Date Picker
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';

// Components
import { Page } from 'components/ui/layout';
import EventPayoutsTable from 'components/payouts/EventPayoutsTable';

// Utility
import fetchExpresso from 'utility/fetchExpresso';
import moment from 'moment-timezone';
import { useNotification } from 'context/notification';
import { getMomentFromDateAndTime } from 'utility/validation';
import { formatPrice } from 'utility/numbers';


export default function PayoutDialog(props) {
    const { eventId, eventName, hostName, startDate, onSuccess, onCancel } = props;

    // State
    const [state, setState] = useState({
        status: 'loading',
        submitting: false,
        totals: null,
        connect_id: null,
        payouts_enabled: null,
        connect_balance: null,
        payouts: null,
        incidentals: null,
    });

    // Form Values
    const [amount, setAmount] = useState('');
    const [date, setDate] = useState(moment());
    const [time, setTime] = useState(moment());
    const [finalPayout, setFinalPayout] = useState(false);
    const [payoutAllowed, setPayoutAllowed] = useState(true);

    // Errors
    const [errors, setErrors] = useState({
        amount: false,
        date: false,
        time: false
    });

    const { notify } = useNotification();


    useEffect(() => {
        Promise.all([
            fetchExpresso(`/apiv2/events/${eventId}/payouts?tz=${moment.tz.guess()}`),
            fetchExpresso(`/apiv2/events/${eventId}/payout_stats?tz=${moment.tz.guess()}`),
        ])
            .then(res => {
                if (res[0].status !== 200 || res[1].status !== 200) {
                    throw new Error();
                }
                return Promise.all([res[0].json(), res[1].json()]);
            })
            .then(data => {
                const [payouts, stats] = data;

                // Event supports only one payout
                // OR, event has started, so the next payout will be the final one
                if (stats.scheduled_payout_flag !== 1 || stats.event_started === 1) {
                    setFinalPayout(true);
                }

                // Event has been given its final payout
                if (payouts.some(p => p.final_payout_flag === 1)) {
                    setFinalPayout(true);
                    setPayoutAllowed(false);
                }

                const scheduledPayouts = stats.scheduled_payout_flag === 1;

                const totals = {
                    payout_reserve_percent: stats.payout_reserve_percent || 0,
                    total_revenue: stats.total_revenue || 0,
                    total_incidentals: stats.total_incidentals || 0,
                    total_payouts: stats.total_payouts || 0,
                }

                const incidentals = stats.incidentals || [];

                setState(s => ({
                    ...s,
                    status: 'success',
                    scheduledPayouts: scheduledPayouts,
                    totals: totals,
                    connect_id: stats.connect_id,
                    payouts_enabled: stats.payouts_enabled,
                    connect_balance: stats.connect_balance,
                    payouts: payouts,
                    incidentals: incidentals
                }));
            })
            .catch(() => {
                setState(s => ({ ...s, status: 'error' }))
            })
    }, [eventId]);


    const handleSubmit = () => {
        if (state.submitting) return;

        // Set error fields
        let _errors = {
            amount: amount === '',
            date: date === null || date.toString() === "Invalid Date",
            time: time === null || time.toString() === "Invalid Date",
        }

        // Return if one or more errors were set to true
        if (Object.values(_errors).some((value) => value)) {
            setErrors(_errors);
            return;
        }

        const fullDate = getMomentFromDateAndTime(date, time);
        const finalAmount = Number(amount);

        setState(s => ({ ...s, submitting: true }));

        fetchExpresso(`/admin/events/${eventId}/payouts`, {
            method: 'POST',
            body: { amount: finalAmount, date: fullDate, final: finalPayout }
        })
            .then(res => {
                if (res.status === 200) {
                    notify.success('Payout successful');
                    onSuccess();
                } else {
                    notify.error('Unable to payout host');
                    setState(s => ({ ...s, submitting: false }));
                }
            })
    }

    function handlePriceChange(e) {
        let value = e.target.value.replace(/[^0-9.]/g, '');

        // Add 0 if there is nothing before the decimal point
        if (value.includes('.') && value.split('.')[0] === '') {
            value = '0' + value;
        }
        // Check if value is a number. Remove extra digits after decimal point
        if (!isNaN(value) || value === '') {
            if (value.includes('.') && value.split('.')[1].length > 2) {
                value = value.slice(0, value.indexOf('.') + 3);
            }
            setAmount(value);
        }
    }

    const copyToClipboard = async (value) => {
        try {
            await navigator.clipboard.writeText(value);
            notify.info('Copied to clipboard!')
        } catch(e) {
            notify.error('Unable to copy to clipboard')
        }
    }


    if (state.status === 'loading') {
        return (
            <Page>
                <Box display='flex' justifyContent='center' alignItems='center' height={72}>
                    <CircularProgress />
                </Box>
            </Page>
        )
    }

    if (state.status === 'error') {
        return (
            <Page>
                <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' mt={12}>
                    <Typography variant='h5'>There was a problem finding your payouts</Typography>
                </Box>
            </Page>
        )
    }

    const payout_reserve_percent = state.totals?.payout_reserve_percent || 0;
    const total_revenue = state.totals?.total_revenue || 0;
    const total_incidentals = state.totals?.total_incidentals || 0;
    const total_payouts = state.totals?.total_payouts || 0;

    const totalPayout = Number((total_revenue - total_incidentals).toFixed(2));

    let reserve = finalPayout ? 0 : Number((totalPayout * payout_reserve_percent).toFixed(2));

    const nextPayout = Number((Number((totalPayout - total_payouts).toFixed(2)) - reserve).toFixed(2));

    return (
        <Root>
            <Container>
                <Page width='md'>

                    <Box mb={4}>
                        <ConnectAlert
                            connect_id={state.connect_id}
                            payouts_enabled={state.payouts_enabled}
                        />
                    </Box>

                    <Grid container spacing={3}>

                        <Grid item xs={12} md={6}>
                            <Card>
                                <CardHeader title='Event' />
                                <CardContent>
                                    <MetaData>{eventName}</MetaData>
                                    <MetaData>{hostName}</MetaData>
                                    <MetaData>{moment(startDate).format('YYYY-MM-DD h:mm a')}</MetaData>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Card>
                                <CardHeader title='Breakdown' />
                                <CardContent>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Total Revenue</TableCell>
                                                <TableCell align='right'>{formatPrice(total_revenue)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Total Incidentals</TableCell>
                                                <TableCell align='right'>- {formatPrice(total_incidentals)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <SubTotalBlack>Total Payout</SubTotalBlack>
                                                <SubTotalBlack align='right'>{formatPrice(totalPayout)}</SubTotalBlack>
                                            </TableRow>
                                            <TableRow>
                                                <TableCellTopPadding>Reserve ({Number((payout_reserve_percent * 100).toFixed(2)) + '%'})</TableCellTopPadding>
                                                <TableCellTopPadding align='right'>- {formatPrice(reserve)}</TableCellTopPadding>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Total Paid Out</TableCell>
                                                <TableCell align='right'>- {formatPrice(total_payouts)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <SubtotalCell>Next Payout</SubtotalCell>
                                                <SubtotalCell align='right'>{formatPrice(nextPayout)}</SubtotalCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}>
                            <Card>
                                <CardHeader title='Make a Payment' />
                                <CardContent>
                                    <FormControlLabel
                                        control={<Checkbox disabled={payoutAllowed === false} checked={finalPayout} onChange={e => setFinalPayout(e.target.checked)} />}
                                        label='Final Payout'
                                    />
                                    <AmountDueText onClick={() => copyToClipboard(nextPayout)}>Amount Due: {formatPrice(nextPayout)}</AmountDueText>
                                    {state.connect_id
                                        ? <AmountDueText>Connect Balance: {formatPrice(state.connect_balance)}</AmountDueText>
                                        : <AmountDueText>Connect Balance: n/a</AmountDueText>
                                    }
                                    <AmountField disabled={payoutAllowed === false} autoFocus fullWidth variant='outlined' label="$ Amount" value={amount} error={errors.amount} onChange={handlePriceChange} />
                                    <DateField disabled={payoutAllowed === false} value={date} onChange={setDate} format='yyyy-MM-dd' inputVariant='outlined' label='Date' />
                                    <TimeField disabled={payoutAllowed === false} value={time} onChange={setTime} inputVariant='outlined' label='Time' />
                                </CardContent>
                                <CardActions>
                                    <Button disabled={state.submitting} onClick={onCancel} variant='outlined'>Cancel</Button>
                                    <Button disabled={state.submitting || payoutAllowed === false} onClick={handleSubmit} variant='contained' color='secondary'>Pay</Button>
                                </CardActions>
                            </Card>
                        </Grid>

                        <Grid item xs={12}>
                            <Card>
                                <CardHeader title='Payout History' />
                                <CardContent>
                                    <EventPayoutsTable payouts={state.payouts} />
                                </CardContent>
                            </Card>
                        </Grid>
                        
                        <Grid item xs={12}>
                            <Card>
                                <CardHeader title='Incidentals' />
                                <CardContent>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCellHeader>WHEN</TableCellHeader>
                                                    <TableCellHeader>DESC</TableCellHeader>
                                                    <TableCellHeader align='right'>AMOUNT</TableCellHeader>
                                                    <TableCellHeader align='right'>TAX</TableCellHeader>
                                                    <TableCellHeader align='right'>TOTAL</TableCellHeader>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCellEmpty></TableCellEmpty>
                                                </TableRow>
                                                {state.incidentals.map(i => (
                                                    <TableRow key={i.inc_id}>
                                                        <TableCell>
                                                            {moment(i.inc_date).get('year') === moment().get('year')
                                                                ? moment(i.inc_date).format('MMM Do')
                                                                : moment(i.inc_date).format('MMM Do YYYY')
                                                            }
                                                        </TableCell>
                                                        <TableCell>{i.inc_desc}</TableCell>
                                                        <TableCell align='right'>{formatNumber(i.sub_total)}</TableCell>
                                                        <TableCell align='right'>{formatNumber(i.tax_total)}</TableCell>
                                                        <TableCell align='right'>{formatNumber(i.total)}</TableCell>
                                                    </TableRow>
                                                ))}
                                                {state.incidentals.length > 0 ? (
                                                    <TableRow>
                                                        <SubtotalCell>Total Incidentals</SubtotalCell>
                                                        <SubtotalCell colSpan={4} align='right'>({formatNumber(total_incidentals)})</SubtotalCell>
                                                    </TableRow>
                                                ) : (
                                                    <TableRow>
                                                        <TableCell align='center' colSpan={5}>No incidentals found</TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CardContent>
                            </Card>
                        </Grid>

                    </Grid>
                </Page>
            </Container>
        </Root>
    )
}


function ConnectAlert({ connect_id, payouts_enabled }) {
    // Stripe Connect Payout
    if (connect_id) {
        if (payouts_enabled) {
            return <Alert severity='success'>Stripe Connect payouts are supported.</Alert>;
        } else {
            return <Alert severity='error'>Payouts for this Stripe Connect account are disabled.</Alert>;
        }
    }
    
    // Manual Payout Required
    else {
        return <Alert severity='info'>This host does not use Stripe Connect. Manual payouts are required.</Alert>;
    }
}


function formatNumber(num) {
    return num.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
}


const Root = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.grey[100],
    height: '100%'
}));

const Container = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.grey[100]
}));

const AmountField = styled(TextField)({
    marginTop: 24,
    marginBottom: 24
});

const DateField = styled(KeyboardDatePicker)({
    marginRight: 24,
    marginBottom: 24
});

const TimeField = styled(KeyboardTimePicker)({
    marginRight: 24,
    marginBottom: 24
});

const TableCellHeader = styled(_TableCell)(({ theme }) => ({
    ...theme.typography.subHeader,
    fontWeight: 'bold',
    borderBottom: '0.5px solid'
}));

const TableCell = styled(_TableCell)(({ theme }) => ({
    border: 'none',
    paddingTop: 0
}));

const TableCellEmpty = styled(_TableCell)(({ theme }) => ({
    border: 'none',
    paddingBottom: 0
}));

const TableCellTopPadding = styled(_TableCell)(({ theme }) => ({
    border: 'none',
}));

const SubtotalCell = styled(_TableCell)(({ theme }) => ({
    border: 'none',
    borderTop: '0.5px solid',
    fontWeight: 'bold'
}));

const SubTotalBlack = styled(_TableCell)(({ theme }) => ({
    border: 'none',
    paddingTop: 0,
    borderBottom: '0.5px solid',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2)
}));

const MetaData = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2)
}));

const AmountDueText = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    marginTop: theme.spacing(2)
}));