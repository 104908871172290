import React, { useState, useEffect } from 'react';

// Material UI
import { styled, Box, Typography, DialogContentText, CircularProgress, FormGroup } from '@material-ui/core';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import { Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { FormControl, FormControlLabel, Select, RadioGroup, Radio, Checkbox } from '@material-ui/core';
import { ToggleButton as _ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';

// Components
import { LoadingState, ErrorState } from 'components/ui/states';
import { ActionButton } from 'components/ui/buttons';
import { TextField, TextArea } from 'components/ui/inputs';
import { CancelButton, SubmitButton } from 'components/ui/forms';

// Utility
import fetchExpresso from 'utility/fetchExpresso';
import { useTextInput, useNumberInput, useDatePicker, useToggle } from 'hooks/forms';
import { useNotification } from 'context/notification';
import { FieldTypes } from 'utility/registration';
import { datePickerTheme } from 'context/theme';
import moment from 'moment-timezone';


export default function RegistrationAnswers(props) {
    const { eventId, tranId } = props;

    const [state, setState] = useState({
        attendees: null,
        loading: true,
        error: false,
        refresh: 0
    });

    // Set these fields to trigger the edit dialog to open
    const [editState, setEditState] = useState({
        open: false,
        attendeeId: null,
        questionId: null,
    });


    useEffect(() => {
        fetchExpresso(`/apiv2/events/${eventId}/orders/${tranId}/attendees`)
            .then(async res => {
                if (res.status === 404) {
                    // There are no checkout responses to display
                    setState(s => ({ ...s, attendees: [], loading: false, error: false }));    
                    return;
                }

                if (res.status !== 200) {
                    // Something went wrong
                    throw new Error();
                }

                const { attendees } = await res.json();

                setState(s => ({ ...s, attendees, loading: false, error: false }));
            })
            .catch(e => {
                setState(s => ({ ...s, loading: false, error: true }));
            })
    }, [tranId, eventId, state.refresh]);


    const openEditDialog = (attendeeId, questionId) => {
        setEditState(s => ({ ...s, open: true, attendeeId, questionId }));
    };

    const closeEditDialog = () => {
        setEditState(s => ({ ...s, open: false, attendeeId: null, question: null }));
    }

    const handleEditResponse = () => {
        setState(s => ({ ...s, refresh: s.refresh + 1 }));
        closeEditDialog();
    };


    if (state.loading) {
        return (
            <Card>
                <CardHeader title='Checkout Form Responses' />
                <CardContent>
                    <LoadingState />
                </CardContent>
            </Card>
        )
    }

    if (state.error) {
        return (
            <Card>
                <CardHeader title='Checkout Form Responses' />
                <CardContent>
                    <ErrorState message='Unable to load checkout answers' />
                </CardContent>
            </Card>
        )
    }


    return (
        <Card>
            <CardHeader title='Checkout Form Responses' />
            <CardContent>
                {state.attendees.length === 0 && (
                    <Typography>There are no checkout responses for this order</Typography>
                )}

                {state.attendees.map((attendee, attendeeIndex) => (
                    <Box key={attendeeIndex} mb={4}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={3}>
                                        <AttendeeName>Attendee #{attendeeIndex+1}: {attendee.name}</AttendeeName>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {attendee.response.map((response, responseIndex) => (
                                    <TableRow key={attendeeIndex + '-' + responseIndex}>
                                        <TableCell width={120}>{response.label}</TableCell>
                                        <TableCell>  
                                            <Answer type={response.type} answer={response.answer} />
                                        </TableCell>
                                        <TableCell width={30}>
                                            <ActionButton size='small' onClick={() => openEditDialog(attendee.id, response.questionId)}>Edit</ActionButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                ))}
            </CardContent>

            {editState.open && (
                <EditDialog
                    eventId={eventId}
                    orderId={tranId}
                    attendeeId={editState.attendeeId}
                    questionId={editState.questionId}
                    onClose={closeEditDialog}
                    onEdit={handleEditResponse}
                />
            )}

        </Card>
    )
}


function Answer(props) {
    const { type, answer } = props;

    if (answer === null) {
        return <Typography>-</Typography>
    }

    if (type === 8) {
        // Yes or No answer. Convert 1/0 to text
        return answer === '1'
            ? <Typography>Yes</Typography>
            : <Typography>No</Typography>;
    }

    if (type === 9) {
        return <Typography>{moment(answer).format('YYYY-MM')}</Typography>
    }

    if (type === 10) {
        // Terms and Conditions answer. Convert 1/0 to text
        return answer === '1'
            ? <Typography>Accepted</Typography>
            : <Typography>Rejected</Typography>;
    }

    else {
        return <Typography>{answer}</Typography>
    }
}


function EditDialog(props) {
    const { onEdit, onClose, eventId, orderId, attendeeId, questionId } = props;

    const [state, setState] = useState({
        question: null,
        status: 'loading', // loading, error, success, saving
        submitting: false
    });

    const { notify } = useNotification();


    useEffect(() => {
        fetchExpresso(`/apiv2/events/${eventId}/orders/${orderId}/attendees/${attendeeId}/questions/${questionId}`)
            .then(res => res.json())
            .then(data => {
                setState(s => ({ ...s, question: data.question, status: 'success' }));
            })
            .catch(() => {
                setState(s => ({ ...s, error: true, status: 'error' }));
                notify.error('There was a problem loading this question');
            })
    // eslint-disable-next-line
    }, [eventId, orderId, attendeeId, questionId]);


    const onSubmit = (answer) => {
        setState(s => ({ ...s, submitting: true }));

        fetchExpresso(`/apiv2/events/${eventId}/orders/${orderId}/attendees/${attendeeId}/questions/${questionId}`, {
            method: 'PUT',
            body: { answer }
        })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error();
                }
                onEdit();
            })
            .catch(e => {
                notify.error('There was a problem updating this response');
                setState(s => ({ ...s, submitting: false }));
            })
    };

    return (
        <Dialog maxWidth='sm' fullWidth open={true} onClose={onClose}>
            <DialogTitle>Edit Answer</DialogTitle>
            {state.status === 'loading' && <DialogContent><CircularProgress /></DialogContent>}
            {state.status === 'error' && <DialogContent><DialogContentText>Unable to load question</DialogContentText></DialogContent>}
            {state.status === 'success' && (<>
                {state.question.type === FieldTypes.SHORT_ANSWER         && <ShortAnswer        onSubmit={onSubmit} onClose={onClose} loading={state.submitting} value={state.question.answer.text} />}
                {state.question.type === FieldTypes.PARAGRAPH            && <Paragraph          onSubmit={onSubmit} onClose={onClose} loading={state.submitting} value={state.question.answer.text} />}
                {state.question.type === FieldTypes.SINGLE_CHOICE        && <SingleChoice       onSubmit={onSubmit} onClose={onClose} loading={state.submitting} value={state.question.answer.options} options={state.question.options} />}
                {state.question.type === FieldTypes.MULTIPLE_CHOICE      && <MultipleChoice     onSubmit={onSubmit} onClose={onClose} loading={state.submitting} value={state.question.answer.options} options={state.question.options} />}
                {state.question.type === FieldTypes.DROPDOWN             && <Dropdown           onSubmit={onSubmit} onClose={onClose} loading={state.submitting} value={state.question.answer.options} options={state.question.options} />}
                {state.question.type === FieldTypes.FULL_DATE            && <FullDate           onSubmit={onSubmit} onClose={onClose} loading={state.submitting} value={state.question.answer.date} />}
                {state.question.type === FieldTypes.AGE                  && <Age                onSubmit={onSubmit} onClose={onClose} loading={state.submitting} value={state.question.answer.integer} />}
                {state.question.type === FieldTypes.YES_OR_NO            && <YesOrNo            onSubmit={onSubmit} onClose={onClose} loading={state.submitting} value={state.question.answer.boolean} />}
                {state.question.type === FieldTypes.SHORT_DATE           && <ShortDate          onSubmit={onSubmit} onClose={onClose} loading={state.submitting} value={state.question.answer.date} />}
                {state.question.type === FieldTypes.TERMS_AND_CONDITIONS && <TermsAndConditions onSubmit={onSubmit} onClose={onClose} loading={state.submitting} value={state.question.answer.boolean} />}
            </>)}
        </Dialog>
    )
}


function ShortAnswer({ value, onClose, onSubmit, loading }) {
    const userInput = useTextInput({ defaultValue: value, max: 2000 });
    const disabled = loading || userInput.value === value || userInput.value.trim() === '';

    const handleSubmit = () => {
        onSubmit({
            text: userInput.value.trim(),
            date: null,
            integer: null,
            boolean: null,
            options: null,
        });
    }

    return (<>
        <DialogContent>
            <DialogContentText>Placeholder: Question Goes Here</DialogContentText>
            <TextField {...userInput.formProps} />
        </DialogContent>
        <DialogActions>
            <CancelButton onClick={onClose}>Cancel</CancelButton>
            <SubmitButton onClick={handleSubmit} disabled={disabled}>Save</SubmitButton>
        </DialogActions>
    </>)
}

function Paragraph({ value, onClose, onSubmit, loading }) {
    const userInput = useTextInput({ defaultValue: value, max: 2000 });
    const disabled = loading || userInput.value === value || userInput.value.trim() === '';

    const handleSubmit = () => {
        onSubmit({
            text: userInput.value.trim(),
            date: null,
            integer: null,
            boolean: null,
            options: null,
        });
    }

    return (<>
        <DialogContent>
            <DialogContentText>Placeholder: Question Goes Here</DialogContentText>
            <TextArea {...userInput.formProps} />
        </DialogContent>
        <DialogActions>
            <CancelButton onClick={onClose}>Cancel</CancelButton>
            <SubmitButton onClick={handleSubmit} disabled={disabled}>Save</SubmitButton>
        </DialogActions>
    </>)
}

function SingleChoice({ value, options, onClose, onSubmit, loading }) {
    // Option IDs are numbers, but we store them as strings in the form since RadioButtons expect strings
    // There will only be one ID in the answer array
    const userInput = useTextInput({ defaultValue: String(value[0]) });

    const handleSubmit = () => {
        onSubmit({
            text: null,
            date: null,
            integer: null,
            boolean: null,
            options: [Number(userInput.value)],
        });
    }

    return (<>
        <DialogContent>
            <DialogContentText>Placeholder: Question Goes Here</DialogContentText>
            <FormControl>
                <RadioGroup aria-label={'single choice'} name={'single choice'} {...userInput.formProps}>
                    {options.map(o => (
                        <FormControlLabel
                            key={o.id}
                            value={String(o.id)}
                            control={<Radio />}
                            label={o.label}
                        />
                    ))}
                </RadioGroup>
            </FormControl>
        </DialogContent>
        <DialogActions>
            <CancelButton onClick={onClose}>Cancel</CancelButton>
            <SubmitButton onClick={handleSubmit} disabled={loading}>Save</SubmitButton>
        </DialogActions>
    </>)
}

function MultipleChoice({ value, options, onClose, onSubmit, loading }) {
    const [selected, setSelected] = useState(new Set(value));

    const handleChange = (id, checked) => {
        const newSelected = new Set(selected);
        checked ? newSelected.add(id) : newSelected.delete(id);
        setSelected(newSelected);
    };

    const handleSubmit = () => {
        onSubmit({
            text: null,
            date: null,
            integer: null,
            boolean: null,
            options: Array.from(selected),
        });
    }

    return (<>
        <DialogContent>
            <DialogContentText>Placeholder: Question Goes Here</DialogContentText>
            <FormControl>
                <FormGroup>
                    {options.map(o => (
                        <FormControlLabel
                            key={o.id}
                            control={
                                <Checkbox
                                    checked={selected.has(o.id)}
                                    onChange={(e) => handleChange(o.id, e.target.checked)}
                                />
                            }
                            label={o.label}
                        />
                    ))}
                </FormGroup>
            </FormControl>
        </DialogContent>
        <DialogActions>
            <CancelButton onClick={onClose}>Cancel</CancelButton>
            <SubmitButton onClick={handleSubmit} disabled={loading}>Save</SubmitButton>
        </DialogActions>
    </>)
}

function Dropdown({ value, options, onClose, onSubmit, loading }) {
    // Option IDs are numbers, but we store them as strings in the form since the dropdown picker uses string values
    // There will only be one ID in the answer array
    const userInput = useTextInput({ defaultValue: String(value[0]) });

    const handleSubmit = () => {
        onSubmit({
            text: null,
            date: null,
            integer: null,
            boolean: null,
            options: [Number(userInput.value)],
        });
    }

    return (<>
        <DialogContent>
            <DialogContentText>Placeholder: Question Goes Here</DialogContentText>
            <FormControl variant='outlined'>
                <Select native {...userInput.formProps}>
                    <option disabled value=''></option>
                    {options.map(o => (
                        <option key={o.id} value={o.id}>{o.label}</option>
                    ))}
                </Select>
            </FormControl>
        </DialogContent>
        <DialogActions>
            <CancelButton onClick={onClose}>Cancel</CancelButton>
            <SubmitButton onClick={handleSubmit} disabled={loading}>Save</SubmitButton>
        </DialogActions>
    </>)
}

function FullDate({ value, onClose, onSubmit, loading }) {
    const userInput = useDatePicker({ defaultValue: value });

    const disabled = loading || userInput.value.isValid() === false;

    const handleSubmit = () => {
        onSubmit({
            text: null,
            date: userInput.value.format('YYYY-MM-DD'),
            integer: null,
            boolean: null,
            options: null,
        });
    }

    return (<>
        <DialogContent>
            <DialogContentText>Placeholder: Question Goes Here</DialogContentText>
            <ThemeProvider theme={datePickerTheme}>
                <KeyboardDatePicker
                    {...userInput.formProps}
                    color='secondary'
                    placeholder='YYYY-MM-DD'
                    format='yyyy-MM-dd' // date-fns doesn't like YYYY-MM-DD. We can switch back when we replace date-fns with day.js (or moment)
                    inputVariant='outlined'
                />
            </ThemeProvider>
        </DialogContent>
        <DialogActions>
            <CancelButton onClick={onClose}>Cancel</CancelButton>
            <SubmitButton onClick={handleSubmit} disabled={disabled}>Save</SubmitButton>
        </DialogActions>
    </>)
}

function Age({ value, onClose, onSubmit, loading }) {
    const userInput = useNumberInput({ defaultValue: value, integerOnly: true, maxLength: 3 });

    const handleSubmit = () => {
        onSubmit({
            text: null,
            date: null,
            integer: userInput.value,
            boolean: null,
            options: null,
        });
    }

    return (<>
        <DialogContent>
            <DialogContentText>Placeholder: Question Goes Here</DialogContentText>
            <TextField {...userInput.formProps} />
        </DialogContent>
        <DialogActions>
            <CancelButton onClick={onClose}>Cancel</CancelButton>
            <SubmitButton onClick={handleSubmit} disabled={loading}>Save</SubmitButton>
        </DialogActions>
    </>)
}

function YesOrNo({ value, onClose, onSubmit, loading }) {
    const [boolean, setBoolean] = useState(value);

    const disabled = loading || boolean === value;

    const handleSubmit = () => {
        onSubmit({
            text: null,
            date: null,
            integer: null,
            boolean: boolean,
            options: null,
        });
    }

    return (<>
        <DialogContent>
            <DialogContentText>Placeholder: Question Goes Here</DialogContentText>
            <FormControl>
                <ToggleButtonGroup
                    value={String(boolean)}
                    size='small'
                    exclusive
                    onChange={(e,v) => setBoolean(v === 'true')}
                    aria-label={'yes or no'}
                >
                    <ToggleButton value="true" aria-label={'yes'}>
                        <Typography>Yes</Typography>
                    </ToggleButton>
                    <ToggleButton value="false" aria-label={'no'}>
                        <Typography>No</Typography>
                    </ToggleButton>
                </ToggleButtonGroup>
            </FormControl>
        </DialogContent>
        <DialogActions>
            <CancelButton onClick={onClose}>Cancel</CancelButton>
            <SubmitButton onClick={handleSubmit} disabled={disabled}>Save</SubmitButton>
        </DialogActions>
    </>)
}

function ShortDate({ value, onClose, onSubmit, loading }) {
    const userInput = useDatePicker({ defaultValue: value });

    const disabled = loading || userInput.value.isValid() === false;

    const handleSubmit = () => {
        onSubmit({
            text: null,
            date: userInput.value.format('YYYY-MM-01'),
            integer: null,
            boolean: null,
            options: null,
        });
    }

    return (<>
        <DialogContent>
            <DialogContentText>Placeholder: Question Goes Here</DialogContentText>
            <ThemeProvider theme={datePickerTheme}>
                <KeyboardDatePicker
                    {...userInput.formProps}
                    color='secondary'
                    placeholder='YYYY-MM'
                    format='yyyy-MM' // date-fns doesn't like YYYY-MM. We can switch back when we replace date-fns with day.js (or moment)
                    views={['year', 'month']}
                    inputVariant='outlined'
                />
            </ThemeProvider>
        </DialogContent>
        <DialogActions>
            <CancelButton onClick={onClose}>Cancel</CancelButton>
            <SubmitButton onClick={handleSubmit} disabled={disabled}>Save</SubmitButton>
        </DialogActions>
    </>)
}

function TermsAndConditions({ value, onClose, onSubmit, loading }) {
    const userInput = useToggle({ defaultValue: value });

    const disabled = loading || userInput.checked === value;

    const handleSubmit = () => {
        onSubmit({
            text: null,
            date: null,
            integer: null,
            boolean: userInput.checked,
            options: null,
        });
    }

    return (<>
        <DialogContent>
            <DialogContentText>Placeholder: Question Goes Here</DialogContentText>
            <FormControl>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox {...userInput.formProps} />}
                        label={'I agree'}
                    />
                </FormGroup>
            </FormControl>
        </DialogContent>
        <DialogActions>
            <CancelButton onClick={onClose}>Cancel</CancelButton>
            <SubmitButton onClick={handleSubmit} disabled={disabled}>Save</SubmitButton>
        </DialogActions>
    </>)
}


const AttendeeName = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
}));

const ToggleButton = styled(_ToggleButton)(({ theme }) => ({
    width: 100,
    borderColor: theme.palette.grey[700],
    color: theme.palette.grey[700],
    '&.Mui-selected': {
        borderColor: theme.palette.info.dark,
        color: theme.palette.info.dark,
        backgroundColor: alpha(theme.palette.info.light, 0.2)
    }
}));