import React, { useState, useEffect } from 'react';

// Material-UI
import { Tabs, Tab } from '@material-ui/core';
import { styled, Box, Paper, CircularProgress, Typography, Chip, Menu, MenuItem, IconButton, TextField } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';

// Components
import PayoutDialog from './PayoutDialog';
import FullScreenModal from 'components/popups/FullScreenModal';
import Tooltip from 'components/popups/Tooltip';
import HostForm from 'components/HostForm';

// Utility
import moment from 'moment-timezone';
import fetchExpresso from 'utility/fetchExpresso';
import { useHistory } from 'react-router-dom';
import { formatPrice } from 'utility/numbers';
import { normalizeCharacters } from 'utility/strings';


const FILTER = {
    0: 'UPCOMING',
    1: 'FUTURE',
    2: 'PAST',
    3: 'UNPAID'
}

const STATUS = {
    'draft':    { sort: 1, display: 'Draft' },
    'unlisted': { sort: 2, display: 'Unlisted' },
    'published':{ sort: 3, display: 'Published' },
    'paidout':  { sort: 4, display: 'Paid Out' }
}


function getDefaultTab(tabName) {
    switch (tabName) {
        case 'recent':
            return 0;
        case 'future':
            return 1;
        case 'past':
            return 2;
        case 'unpaid':
            return 3;
        default:
            return 0;
    }
}


export default function AdminEventList(props) {
    const { payoutCount, refreshPayoutCount, defaultTab } = props;

    const history = useHistory();


    // === Tab Menu (Date Range Picker) === //

    const [tabIndex, setTabIndex] = useState(getDefaultTab(defaultTab));
    
    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const [data, loading, error, refresh] = useAdminEvents(FILTER[tabIndex]);


    // === Overflow Menu (More Options) === //

    const [menu, setMenu] = useState({ open: false, anchorEl: null, event: null });

    const handleMenuOpen = (anchorEl, eventId) => {
        let event;

        for (const e of data) {
            if (e.event_id === eventId) {
                event = e;
                break;
            }
        }

        setMenu({ open: true, anchorEl, event: event })
    };

    const handleMenuClose = () => {
        setMenu({ open: false, anchorEl: null, event: null })
    };


    // === Payout Popup === //

    const [payout, setPayout] = useState({ open: false, eventId: null, eventName: null, hostName: null, startDate: null });

    const handlePayout = (event) => {
        setPayout({ open: true, eventId: event.event_id, eventName: event.event_title, hostName: event.host_name, startDate: event.start_date });
        handleMenuClose();
    };

    const handlePayoutSubmit = () => {
        setPayout({ open: false, eventId: null, eventName: null, hostName: null, startDate: null });
        refresh();
        refreshPayoutCount();
    };
    const handlePayoutCancel = () => {
        setPayout({ open: false, eventId: null, eventName: null, hostName: null, startDate: null });
    };


    // === Host Pricing === //

    const handleViewPricing = (event) => {
        history.push(`/admin/hosts/${event.host_id}/host_pricing?host=${encodeURIComponent(event.host_name)}`);
    }


    // === Edit Host === //

    const [modal, setModal] = useState({ open: false, hostId: null });

    const handleHostEdit = (event) => {
        setModal({ open: true, hostId: event.host_id });
        handleMenuClose();
    };

    const handleModalClose = () => {
        setModal({ open: false, hostId: null });
    };

    const handleHostUpdate = () => {
        setModal({ open: false, hostId: null });
        refresh();
    };


    if (loading) {
        return (
            <Box display='flex' pt={5} justifyContent='center'>
                <CircularProgress disableShrink />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display='flex' pt={5} justifyContent='center'>
                <Typography>There was a problem loading events</Typography>
            </Box>
        );
    }


    const unpaidLabel = payoutCount > 0
        ? `Unpaid (${payoutCount})`
        : 'Unpaid'

    return (
        <Container>

            <Tabs value={tabIndex} onChange={handleTabChange}>
                <Tab label='Current' />
                <Tab label='Future' />
                <Tab label='Past' />
                <Tab label={unpaidLabel} />
            </Tabs>

            <Box flexGrow={1}>
                <TableContainer>
                    <EventTable
                        events={data}
                        handleMenuOpen={handleMenuOpen}
                    />
                </TableContainer>
            </Box>

            <Menu anchorEl={menu.anchorEl} keepMounted open={menu.open} onClose={handleMenuClose}>
                <MenuItem onClick={() => handlePayout(menu.event)}>Payout Event</MenuItem>
                <MenuItem onClick={() => handleViewPricing(menu.event)}>Edit Host Pricing</MenuItem>
                <MenuItem onClick={() => handleHostEdit(menu.event)}>Edit Host</MenuItem>
            </Menu>            

            <FullScreenModal open={payout.open} onClose={handlePayoutCancel} title='Payout Event' cancelText='Cancel'>
                <PayoutDialog
                    eventId={payout.eventId}
                    eventName={payout.eventName}
                    hostName={payout.hostName}
                    startDate={payout.startDate}
                    onSuccess={handlePayoutSubmit}
                    onCancel={handlePayoutCancel}
                />
            </FullScreenModal>

            <FullScreenModal open={modal.open} onClose={handleModalClose} title='Edit Host'>
                <HostForm hostId={modal.hostId} onUpdate={handleHostUpdate} onCancel={handleModalClose} />
            </FullScreenModal>

        </Container>

    );
}


function EventTable(props) {
    const { events, handleMenuOpen } = props;

    const [dataset, setDataset] = useState([]); // Filtered list of events
    const [searchText, setSearchText] = useState('');


    // Whenever the list of events from the parent component is updated, we need to update the dataset, and clear all filters to keep the UI in sync.
    // On first render, this will just set the initial value for 'dataset'
    useEffect(() => {
        setDataset(events);
        setSearchText('');
    }, [events]);


    // ***** Search / Filter ***** //

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);

        const searchStr = normalizeCharacters(searchValue.trim()); // Normalize string to remove accents and diacritics
        
        const searchRegex = new RegExp(searchStr.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
        
        const filteredRows = events.filter((row) => {
            const eventTitle = normalizeCharacters(row.event_title);
            const hostName = normalizeCharacters(row.host_name);
            return searchRegex.test(eventTitle) || searchRegex.test(hostName);
        });
        
        setDataset(filteredRows);
    };


    // ***** DataGrid Rows and Columns ***** //

    const rows = dataset.map(e => ({
        id: e.event_id,
        c1: { paid: e.final_payout_flag, status: e.event_status },
        c2: { event: e.event_title, host: e.host_name },
        c3: { start: e.start_date, end: e.end_date, timezone: e.time_zone, final: e.final_payout_flag },
        c4: { updated: e.event_updated_at, by: e.event_updated_by },
        c5: e.ticket_updated_at,
        c6: e.order_count,
        c7: e.ticket_count,
        c8: { total: e.amount_total, online: e.amount_total_online, pos: e.amount_total_pos },
        c9: e.amount_total_cash,
        c10: { total: e.our_fee_total, online: e.our_fee_total_online, pos: e.our_fee_total_pos },
        c11: e.cc_fee_total,
        c12: e.inc_total,
        c13: e.balance,
        c14: e.total_paid_out,
        c15: e.event_id
    }));

    const columns = [
        { field: 'c1',  headerName: 'STATUS',     width: 90,  hideSortIcons: true,
            renderCell: (params) => (
                <Box width='100%' display='flex' justifyContent='center'>
                    <Tooltip color='grey' message={STATUS[params.value.status].display}>
                        <Box>
                            {params.value.status === 'paidout'   && <PaidIcon />}
                            {params.value.status === 'published' && <PublishedChip>P</PublishedChip>}
                            {params.value.status === 'unlisted'  && <UnlistedChip>U</UnlistedChip>}
                            {params.value.status === 'draft'     && <DraftChip>D</DraftChip>}
                        </Box>
                    </Tooltip>
                </Box>
            ),
            sortComparator: (v1, v2, param1, param2) => (STATUS[param1.value.status].sort - STATUS[param2.value.status].sort)
        },
        { field: 'c2',  headerName: 'EVENT',    minWidth: 300, flex: 1,  hideSortIcons: true,
            renderCell: (params) => (
                <EventName onClick={e => handleMenuOpen(e.currentTarget, params.id)}>
                    <Typography variant='body2' display='block'>{params.value.event}</Typography>
                    <Typography variant='caption' display='block'>{params.value.host}</Typography>
                </EventName>
            ),
            sortComparator: (v1, v2, param1, param2) => param1.value.event.localeCompare(param2.value.event)
        },
        { field: 'c3',  headerName: 'WHEN',     width: 170, hideSortIcons: true,
            renderCell: (params) => {
                const startDate = moment(params.value.start).tz(params.value.timezone, true);
                const endDate = moment(params.value.end).tz(params.value.timezone, true);

                return (
                    <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
                        <DateChip endDate={endDate} paid={params.value.final === 1} />
                        <EventDate display='block' variant='caption'>{startDate.format('YYYY-MM-DD hh:mma z')}</EventDate>
                        <EventDate display='block' variant='caption'>{endDate.format('YYYY-MM-DD hh:mma z')}</EventDate>
                    </Box>
                )
            },
            sortComparator: (v1, v2, param1, param2) => (moment(param1.value.start).unix() - moment(param2.value.start).unix())
        },
        { field: 'c4',  headerName: 'E.UPD',    width: 120,  hideSortIcons: true,
            renderCell: (params) => (
                <Tooltip color='grey' message={params.value.by}>
                    {moment().diff(params.value.updated, 'hours') >= 24
                        ? <Typography variant='caption' display='block'>{moment(params.value.updated).fromNow()}</Typography>
                        : <BoldCaption>{moment(params.value.updated).fromNow()}</BoldCaption>
                    }
                </Tooltip>
            ),
            sortComparator: (v1, v2, param1, param2) => ((moment(param1.value.updated).unix() || 0) - (moment(param2.value.updated).unix() || 0))
        },
        { field: 'c5',  headerName: 'T.UPD',    width: 120, hideSortIcons: true,
            renderCell: (params) => (
                <>
                    {params.value && moment().diff(params.value, 'hours') >= 24 && (
                        <Typography variant='caption' display='block'>{moment(params.value).fromNow()}</Typography>
                    )}
                    {params.value && moment().diff(params.value, 'hours') < 24 && (
                        <BoldCaption>{moment(params.value).fromNow()}</BoldCaption>
                    )}
                </>
            ),
            sortComparator: (v1, v2, param1, param2) => ((moment(param1.value).unix() || 0) - (moment(param2.value).unix() || 0))
        },
        { field: 'c6',  headerName: 'ORD',      width: 70,  hideSortIcons: true, type: 'number' },
        { field: 'c7',  headerName: 'TIX',      width: 70,  hideSortIcons: true, type: 'number' },
        { field: 'c8',  headerName: 'AMT',      width: 100, hideSortIcons: true, type: 'number',
            renderCell: (params) => (
                <Box width='100%' display='flex' justifyContent='flex-end'>
                    <Tooltip color='grey' message={(
                        <Box>
                            <Typography>Online: {formatPrice(params.value.online)}</Typography>
                            <Typography>In-Person: {formatPrice(params.value.pos)}</Typography>
                        </Box>
                    )}>
                        <Box>{formatPrice(params.value.total)}</Box>
                    </Tooltip>
                </Box>
            ),
            sortComparator: (v1, v2, param1, param2) => (param1.value.total - param2.value.total)
        },
        { field: 'c9',  headerName: 'CASH',     width: 100, hideSortIcons: true, type: 'number', valueFormatter: params => formatPrice(params.value) },
        { field: 'c10', headerName: 'FD',       width: 100, hideSortIcons: true, type: 'number',
            renderCell: (params) => (
                <Box width='100%' display='flex' justifyContent='flex-end'>
                    <Tooltip color='grey' message={(
                        <Box>
                            <Typography>Online: {formatPrice(params.value.online)}</Typography>
                            <Typography>In-Person: {formatPrice(params.value.pos)}</Typography>
                        </Box>
                    )}>
                        <Box>{formatPrice(params.value.total)}</Box>
                    </Tooltip>
                </Box>
            ),
            sortComparator: (v1, v2, param1, param2) => (param1.value.total - param2.value.total)
        },
        { field: 'c11', headerName: 'CC',       width: 100, hideSortIcons: true, type: 'number', valueFormatter: params => formatPrice(params.value) },
        { field: 'c12', headerName: 'INC',      width: 100, hideSortIcons: true, type: 'number', valueFormatter: params => formatPrice(params.value) },
        { field: 'c13', headerName: 'BAL',      width: 100, hideSortIcons: true, type: 'number', valueFormatter: params => formatPrice(params.value) },
        { field: 'c14', headerName: 'PAID',     width: 100, hideSortIcons: true, type: 'number', valueFormatter: params => formatPrice(params.value) },
        { field: 'c15', headerName: ' ',        width: 70,  hideSortIcons: true, align: 'center',
            renderCell: params => (
                <IconButton onClick={e => handleMenuOpen(e.currentTarget, params.value)}>
                    <MoreVertIcon />
                </IconButton>
            )
        },
    ]
    
    return (
        <DG
            components={{ Toolbar: Toolbar }}
            componentsProps={{
                toolbar: {
                  value: searchText,
                  onChange: (event) => requestSearch(event.target.value),
                  clearSearch: () => requestSearch(''),
                },
            }}
            rows={rows}
            columns={columns}
            rowHeight={80}
            disableColumnMenu
            sortingOrder={['desc', 'asc']}
        />
    )
}


function Toolbar(props) {
    const { value, onChange, clearSearch } = props;
    
    return (
        <SearchBar
            variant="outlined"
            size='small'
            value={value}
            onChange={onChange}
            placeholder="Search…"
            InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
                <IconButton
                    title="Clear"
                    aria-label="Clear"
                    size="small"
                    style={{ visibility: value ? 'visible' : 'hidden' }}
                    onClick={clearSearch}
                >
                    <ClearIcon fontSize="small" />
                </IconButton>
            ),
            }}
        />
    )
}


function useAdminEvents(filter) {

    const [state, setState] = useState({
        data: null,
        loading: true,
        error: false
    });

    const [refreshCount, setRefreshCount] = useState(0);
    const refresh = () => setRefreshCount(refreshCount + 1);

    useEffect(() => {

        let endpoint = `/admin/events?tz=${moment.tz.guess()}`;

        switch (filter) {

            case 'UPCOMING':
                endpoint += `&start=${moment().subtract(1, 'month').format('YYYY-MM-DDTHH:mm:ss')}`;
                endpoint += `&end=${moment().add(1, 'month').format('YYYY-MM-DDTHH:mm:ss')}`;
                break;

            case 'FUTURE':
                endpoint += `&start=${moment().format('YYYY-MM-DDTHH:mm:ss')}`;
                break;

            case 'PAST':
                endpoint += `&end=${moment().format('YYYY-MM-DDTHH:mm:ss')}`;
                break;

            case 'UNPAID':
                endpoint += `&end=${moment().format('YYYY-MM-DDTHH:mm:ss')}`;
                endpoint += '&paid=false';
                break;

            default:
                break;
        }

        fetchExpresso(endpoint)
            .then(async res => {
                if (res.status !== 200) {
                    setState(s => ({ ...s, loading: false, error: true }));
                    return;
                }
                const data = await res.json();

                setState(s => ({ ...s, data: data, loading: false, error: false }));
            })
            .catch(e => {
                setState(s => ({ ...s, loading: false, error: true }));
            })
    }, [filter, refreshCount]);


    return [state.data, state.loading, state.error, refresh];
}


function DateChip(props) {
    const { endDate, paid } = props;

    const past = endDate < moment();

    // Past event: needs payout
    if (past === true && paid === false) {
        return <DateChipRed
            size='small'
            label={'ended ' + endDate.fromNow()}
        />
    }

    // Past event: already paid
    if (past === true && paid === true) {
        return <DateChipGrey
            size='small'
            label={'ended ' + endDate.fromNow()}
            variant='outlined'
        />
    }

    // Future Event
    return <DateChipGreen
        size='small'
        label={'ends ' + endDate.fromNow()}
        variant='outlined'
    />
}


const DG = styled(DataGrid)(({ theme }) => ({
    "& .MuiDataGrid-footerContainer": {
        justifyContent: 'flex-start'
    }
}));

const Container = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
}));

const SearchBar = styled(TextField)(({ theme }) => ({
    margin: theme.spacing(2),
    marginBottom: theme.spacing(1)
}));

const TableContainer = styled(Box)(({ theme }) => ({
    height: '100%'
}));

const EventName = styled(Box)(({ theme }) => ({
    '&:hover': {
        cursor: 'pointer',
    }
}));

const EventDate = styled(Typography)(({ theme }) => ({
    marginLeft: theme.spacing(0.5)
}));

const DateChipRed = styled(Chip)(({ theme }) => ({
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
}));

const DateChipGreen = styled(Chip)(({ theme }) => ({
    color: '#2BC126',
    borderColor: '#2BC126',
}));

const DateChipGrey = styled(Chip)(({ theme }) => ({
    color: theme.palette.grey[700],
    borderColor: theme.palette.grey[700],
}));

const PublishedChip = styled(Box)(({ theme }) => ({
    height: 22,
    width: 22,
    border: 'solid',
    borderWidth: 1,
    borderRadius: 11,
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}));

const DraftChip = styled(Box)(({ theme }) => ({
    height: 22,
    width: 22,
    borderRadius: 11,
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}));

const UnlistedChip = styled(Box)(({ theme }) => ({
    height: 22,
    width: 22,
    borderRadius: 11,
    backgroundColor: theme.palette.info.main,
    color: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}));

const PaidIcon = styled(CheckCircleIcon)(({ theme }) => ({
    color: theme.palette.success.main
}));

const BoldCaption = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold'
}));
BoldCaption.defaultProps = { variant: 'caption', display: 'block' }