import React from 'react';
import { styled, Step, StepContent, StepLabel as _StepLabel, Stepper, Box, Typography } from '@material-ui/core';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import moment from 'moment-timezone';

import ReceiptIcon from '@material-ui/icons/Receipt';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import PhonelinkRingIcon from '@material-ui/icons/PhonelinkRing';
import EmailIcon from '@material-ui/icons/Email';


export default function ActivityLog(props) {
    const { activity } = props;

    return (
        <ActivityLogCard>
            <CardHeader disableTypography
                title={(<Typography variant='h5'>Timeline</Typography>)}
                subheader={(<Typography variant='caption'>*Times listed in the local time zone of your event</Typography>)}
            />
            <CardContent>
                <Stepper style={{ padding: 0 }} orientation='vertical' connector={null}>
                    {activity.map((a, index) => (
                        <Step key={index} active={true} color='secondary' style={{ marginBottom: '8px'}}>
                            <StepLabel StepIconComponent={LabelComponent} StepIconProps={{ statusLevel: a.status_level, statusArea: a.status_area }}>
                                {moment(a.action_time_stamp).format('ddd MMM D, YYYY @ h:mm a')}
                            </StepLabel>
                            <StepContent>
                                <Box mb={4}>
                                    <Box mb={1}>
                                        <Typography variant='body2'>{a.action}</Typography>
                                    </Box>
                                    {a.ticket_name && <Typography variant='body2'>{a.ticket_name}</Typography>}
                                </Box>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
            </CardContent>
        </ActivityLogCard>
    );
}

// order ticket wallet refund scan email
function LabelComponent(props) {
    
    let Label = null;
    let Icon = null;

    switch (props.statusLevel) {
        case 'info':
            Label = InfoLabel;
            break;
        case 'warning':
            Label = WarningLabel;
            break;
        case 'fail':
            Label = ErrorLabel;
            break;
        case 'success':
            Label = SuccessLabel;
            break;
        default:
            return null;
    }

    switch (props.statusArea) {
        case 'order':
            Icon = ReceiptIcon;
            break;
        case 'tickets':
            Icon = ConfirmationNumberIcon;
            break;
        case 'wallet':
            Icon = AccountBalanceWalletIcon;
            break;
        case 'refund':
            Icon = RotateLeftIcon;
            break;
        case 'scan':
            Icon = PhonelinkRingIcon;
            break;
        case 'email':
            Icon = EmailIcon;
            break;
        default:
            return null;
    }

    return (
        <Label fontSize={'14px'} color={'white'} width={24} height={24} borderRadius={12} display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <Icon fontSize='inherit' color='inherit' />
        </Label>
    )
}

const InfoLabel = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
}));

const SuccessLabel = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.success.main,
}));

const WarningLabel = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.warning.main,
}));

const ErrorLabel = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.error.main,
}));


const StepLabel = styled(_StepLabel)(({ theme }) => ({
    "& .MuiStepLabel-label" : {
        color: theme.palette.grey[700],
    }
}));

const ActivityLogCard = styled(Card)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        width: 420
    }
}));