import React from 'react';

// Material UI
import { styled, Box, Typography } from '@material-ui/core';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

// Components
import RegistrationField from 'components/comp/CompRegistrationField';
import { FieldTypes, fieldValidator } from 'components/comp/CompRegistrationUtils';
import { ScrollRestoreContainer } from 'components/ui/layout';
import { SubmitButton, CancelButton } from 'components/ui/forms';

// Utility
import { useNotification } from 'context/notification';


export default function CompRegistration(props) {
    const { form, mode, onFormChange, onSubmit, onCancel } = props;

    const { notify } = useNotification();


    const handleAttendeeUpdate = (value) => {
        const attendees = form.map(a => {
            if (a.tempId === value.tempId) {
                return value;
            } else {
                return a;
            }
        });

        onFormChange(attendees);
    };


    // ***** Validate Form Values ***** //

    const handleValidation = () => {

        let formError = false;

        const newAttendeeList = [];


        // Map over every attendee form
        for (const attendee of form) {

            let attendeeForm = [];

            // Map over every field, and check for errors
            // Assemble a new form array containing any new error messages
            for (const field of attendee.form) {

                const { error, errorMsg } = fieldValidator(field.value, field.type, field.required);
    
                if (error) {
                    formError = true;
                    attendeeForm.push({ ...field, errorMsg })
                } else {
                    attendeeForm.push(field)
                }
            }

            newAttendeeList.push({ ...attendee, form: attendeeForm });
        }

        
        if (formError === false) {
            onSubmit();
        } else {
            onFormChange(newAttendeeList);
            notify.warning('Please ensure the form is filled in correctly');
        }
    };


    return (
        <ScrollRestoreContainer>

            <FormContainer>

                <Alert severity='info' variant='standard'>
                    Here are the checkout questions that would be shown to customers making this order. You are not required to fill in this information if you don't want or need to.
                </Alert>

                {form.map((attendee) => (
                    <AttendeeForm
                        attendee={attendee}
                        mode={mode}
                        onAttendeeUpdate={handleAttendeeUpdate}
                    />
                ))}

                <Box display='flex' justifyContent='flex-end' mt={4}>
                    <CancelButton onClick={onCancel}>Back</CancelButton>
                    <SubmitButton onClick={handleValidation}>Next</SubmitButton>
                </Box>

            </FormContainer>

        </ScrollRestoreContainer>
    )
}


function AttendeeForm(props) {
    const { attendee, mode, onAttendeeUpdate } = props;

    const form = attendee.form;


    // ***** Process Form Value Changes ***** //

    const handleChange = (index, value, optionId) => {
        const newForm = Array.from(form);

        switch (form[index].type) {
            case FieldTypes.AGE:
                newForm[index].value = value.replace(/[^0-9]/g,'').slice(0,3);
                break;

            case FieldTypes.MULTIPLE_CHOICE:
                value === true
                    ? newForm[index].value.add(optionId)
                    : newForm[index].value.delete(optionId)
                break;

            default:
                newForm[index].value = value;
                break;
        }

        newForm[index].errorMsg = null;

        onAttendeeUpdate({ ...attendee, form: newForm });
    };


    const containsRequiredFields = form.some(f => Boolean(f.required) === true);


    return (
        <AttendeeFormContainer>
            {mode === 2 && (
                <CardHeader
                    title={attendee.productName}
                    subheader={'Attendee #' + attendee.tempId}
                />
            )}
            <CardContent>
                {form.map((field, index) => (
                    <RegistrationField
                        key={index}
                        index={index}
                        onChange={handleChange}
                        {...field}
                    />
                ))}
            </CardContent>
            {containsRequiredFields && (
                <Box ml={2} mb={1}>
                    <RequiredText variant='body2'>
                        Fields marked with an asterisk are required
                    </RequiredText>
                    <Required />
                </Box>
            )}
        </AttendeeFormContainer>
    )
}


const FormContainer = styled(Box)(({ theme }) => ({
    width: theme.breakpoints.values.md,
    paddingBottom: theme.spacing(10)
}));

const AttendeeFormContainer = styled(Card)(({ theme }) => ({
    marginTop: theme.spacing(4)
}));

const RequiredText = styled(Typography)(({ theme }) => ({
    whiteSpace: 'pre-wrap',
}));
RequiredText.defaultProps = { display: 'inline' };

const Required = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    color: theme.palette.error.dark,
    marginLeft: theme.spacing(1)
}));
Required.defaultProps = { display: 'inline', children: '*' };