import React, { useState, useEffect } from 'react';

// Material UI
import { styled, Box, Typography, CircularProgress, Chip, Paper } from '@material-ui/core';
import { Card, CardHeader, CardContent, CardActions } from '@material-ui/core';
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

import { ActionButton } from './ui/buttons';

// Utility
import moment from 'moment-timezone';
import fetchExpresso from 'utility/fetchExpresso';
import { useAuth } from 'context/auth';


export default function StripeConnect() {

    const [state, setState] = useState({
        status: 'loading', // loading, error, success
        account: null
    });

    const { auth } = useAuth();


    useEffect(() => {
        (async () => {
            try {

                const accountRequest = await fetchExpresso(`/apiv2/hosts/${auth.hostId}/connect/`);

                // ***** Found a connected account ***** //
                if (accountRequest.status === 200) {
                    const account = await accountRequest.json();

                    setState(s => ({
                        ...s,
                        status: 'success',
                        account: account
                    }));
                }

                // ***** No connected account found ***** //
                else if (accountRequest.status === 404) {
                    setState(s => ({
                        ...s,
                        status: 'success',
                        account: null
                    }));
                }
                
                // ***** Unknown error code ***** //
                else {
                    throw new Error();
                }

            } catch(error) {
                setState(s => ({
                    ...s,
                    status: 'error',
                    account: null
                }));
            }
        })()
    }, [auth.hostId, auth.admin]);


    if (state.status === 'loading') {
        return (
            <Card>
                <CardHeader title='Payment Processing' />
                <CardContent>
                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={150}>
                        <CircularProgress />
                    </Box>
                </CardContent>
            </Card>
        )
    }
    
    if (state.status === 'error') {
        return (
            <Card>
                <CardHeader title='Payment Processing' />
                <CardContent>
                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={150}>
                        <Typography>Something went wrong. Please try refreshing the page.</Typography>
                    </Box>
                </CardContent>
            </Card>
        )
    }


    // ***** User is on Legacy Payments System ***** //

    if (state.account === null) {
        return (
            <Card>
                <CardHeader title='Payment Processing' />
                <CardContent>
                    <Option>
                        <Box display='flex' alignItems={'center'}>
                            <OptionName>FrontDoor+ Payments</OptionName>
                            <OptionCaption>(powered by Stripe)</OptionCaption>
                        </Box>
                        <Box ml={2} mt={1}>
                            <Typography>• Accept on-site payments</Typography>
                            <Typography>• More payout options</Typography>
                            <Box height={4} />
                            <ContactMessage>Contact us to learn more</ContactMessage>
                        </Box>
                    </Option>

                    <Box height={12} />

                    <Option>
                        <Box display='flex' alignItems={'center'}>
                            <Check />
                            <OptionName>Legacy Payments</OptionName>
                        </Box>
                        <Box ml={2} mt={1}>
                            <Typography>• On-site payments not supported</Typography>
                            <Typography>• Payouts processed manually</Typography>
                        </Box>
                    </Option>
                </CardContent>
            </Card>
        )
    }


    // ***** User is on Stripe Connect ***** //

    return (
        <Card>
            <CardHeader
                title='FrontDoor+ Payments'
                subheader={state.account.current_deadline ? 'Submission Due: ' + moment(state.account.current_deadline * 1000).format('MMM DD, YYYY') : undefined}
            />
            <CardContent>
                {state.account.onboarding_complete === false ? (
                    <Box mb={4}>
                        <Typography>In order to take payments, you will need to complete your account setup with Stripe. Please click "Complete Setup" below to get started.</Typography>
                    </Box>
                ) : (
                    <Box mb={4}>
                        {state.account.requirements_due === true ? (
                            <Typography>There are problems with your payment account that may impact your ability to take payments and/or receive your money. Click "Complete Setup" to update your profile and upload any requried documents.</Typography>
                        ) : (
                            <Typography>You are enrolled in FrontDoor+ Payments (powered by Stripe). You are good to sell tickets, and will receive payouts to your linked bank account.</Typography>
                        )}
                    </Box>
                )}
                {state.account.onboarding_complete === true && (
                    <Table>
                        <TableBody>
                            <Status
                                title='Payments'
                                enabled={state.account.charges_enabled}
                            />
                            <Status
                                title='Payouts'
                                enabled={state.account.payouts_enabled}
                            />
                        </TableBody>
                    </Table>
                )}
            </CardContent>
            <CardActions>
                <ActionButton href={state.account.onboard_url} target='_blank' rel='noopener noreferrer' disabled={!state.account.onboard_url}>Complete Setup</ActionButton>
                <ActionButton href={state.account.login_url} target='_blank' rel='noopener noreferrer' disabled={!state.account.login_url}>View Dashboard</ActionButton>
            </CardActions>
        </Card>
    )
}


function Status(props) {
    const { enabled, title } = props;

    return (
        <TableRow>
            <TableCell width={8}>
                <Box height={'100%'} display='flex' alignItems='center'>
                    {enabled ? <Check /> : <Cross />}
                </Box>
            </TableCell>
            <TableCell>
                <StatusHeader>{title}</StatusHeader>
            </TableCell>
            <TableCell align='right'>
                {enabled ? <SuccessText>Enabled</SuccessText> : <ErrorText>Disabled</ErrorText> }
            </TableCell>
        </TableRow>
    )
}


const Option = styled(Paper)(({ theme }) => ({
    border: '1px solid',
    borderRadius: theme.spacing(2),
    borderColor: theme.palette.grey[400],
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(2)
}));
Option.defaultProps = { elevation: 0 }

const OptionName = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    fontSize: 18
}));

const OptionCaption = styled(Typography)(({ theme }) => ({
    fontSize: 14,
    marginLeft: theme.spacing(1),
    paddingBottom: 2
}));

const ContactMessage = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold'
}));

const SuccessChip = styled(Chip)(({ theme }) => ({
    backgroundColor: theme.palette.success.main,
    borderColor: theme.palette.success.main,
    color: theme.palette.common.white,
    marginLeft: 'auto'
}));
SuccessChip.defaultProps = { size: 'small' }

const WarningChip = styled(Chip)(({ theme }) => ({
    backgroundColor: theme.palette.warning.main,
    borderColor: theme.palette.warning.main,
    color: theme.palette.common.white,
    marginLeft: 'auto'
}));
WarningChip.defaultProps = { size: 'small' }

const StatusHeader = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold'
}));

const Check = styled(CheckCircleOutlinedIcon)(({ theme }) => ({
    color: theme.palette.success.main,
    marginRight: 4
}));

const Cross = styled(CancelOutlinedIcon)(({ theme }) => ({
    color: theme.palette.error.main,
    marginRight: 4
}));

const SuccessText = styled(Typography)(({ theme }) => ({
    color: theme.palette.success.main,
    fontWeight: 'bold'
}));

const ErrorText = styled(Typography)(({ theme }) => ({
    color: theme.palette.error.main,
    fontWeight: 'bold'
}));