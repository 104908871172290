import React, { useState, useEffect } from 'react';

// Material-UI
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import { styled, CircularProgress, Typography, Box } from '@material-ui/core';
import { PaymentIcon, DiscountIcon, CommentIcon } from 'components/ui/icons';

// Components
import OrderDetailModal from 'components/orders/OrderDetailModal';
import FullScreenModal from 'components/popups/FullScreenModal';

// Utility
import fetchExpresso from 'utility/fetchExpresso';
import { formatPrice } from 'utility/numbers';
import moment from 'moment-timezone';



export default function RecentOrders() {

    const [state, setState] = useState({
        data: null,
        loading: true,
        error: false
    });

    const { data, loading, error } = state;

    const [order, setOrder] = useState({ tranId: null, eventId: null, refreshToken: 0 });


    useEffect(() => {
        fetchExpresso(`/admin/orders?limit=10`)
            .then(async res => {
                if (res.status !== 200) {
                    setState(s => ({ ...s, loading: false, error: true }));
                    return;
                }
                const data = await res.json();
                setState(s => ({ ...s, data: data, loading: false, error: false }));
            })
            .catch(e => {
                setState(s => ({ ...s, loading: false, error: true }));
            })
    }, [order.refreshToken]);

    if (loading) {
        return (
            <Root>
                <Status>
                    <CircularProgress disableShrink />
                </Status>
            </Root>
        );
    }

    if (error) {
        return (
            <Root>
                <Status>
                    <Typography>Unable to load orders</Typography>
                </Status>
            </Root>
        );
    }


    return (
        <Root>

            <CardHeader title='Recent Orders' />

            <CardContent>

                <TableContainer component={Box}>

                    <Table size='small'>

                        <TableHead>

                            <TableRow>

                                <TableCellHeader width={10} variant='head'></TableCellHeader>
                                <TableCellHeader width={60} variant='head'>ORDER</TableCellHeader>
                                <TableCellHeader width={60} variant='head'>DETAIL</TableCellHeader>
                                <TableCellHeader            variant='head'>EVENT</TableCellHeader>
                                <TableCellHeader width={60} variant='head' align='right'>TIX</TableCellHeader>
                                <TableCellHeader width={80} variant='head' align='right'>TOTAL</TableCellHeader>
                                <TableCellHeader width={80} variant='head' align='right'>FD</TableCellHeader>
                                <TableCellHeader width={100} variant='head' align='right'>WHEN</TableCellHeader>

                            </TableRow>

                        </TableHead>

                        <TableBody>

                            {data.map(r => (
                                <BodyRow key={r.tran_id} onClick={() => setOrder(s => ({ ...s, tranId: r.tran_id, eventId: r.event_id }))}>
                                    <TableCell >
                                        <PaymentIcon status={r.status_financial} message={r.decline_message} />
                                    </TableCell>
                                    <TableCell>F{r.tran_id}</TableCell>
                                    <TableCell>
                                        <Box display='flex'>
                                            <DiscountIcon code={r.discount_code} compedBy={r.comped_by} />
                                            <CommentIcon count={r.comment_count} />
                                        </Box>
                                    </TableCell>
                                    <EventTitleCell>
                                        <EventTitleText noWrap variant='body2'>{r.event_title}</EventTitleText>
                                        <Typography variant='caption'>{r.first_name + ' ' + r.last_name}</Typography>
                                    </EventTitleCell>
                                    <TableCell align='right'>{r.total_tickets}</TableCell>
                                    <TableCell align='right'>{formatPrice(r.total)}</TableCell>
                                    <TableCell align='right'>{formatPrice(r.our_fee_total)}</TableCell>
                                    <TableCell align='right'><Typography noWrap variant='body2'>{moment(r.created_at).fromNow()}</Typography></TableCell>
                                </BodyRow>
                            ))}

                        </TableBody>

                    </Table>

                </TableContainer>

            </CardContent>

            <FullScreenModal open={order.tranId ? true : false} onClose={() => setOrder(s => ({ ...s, tranId: null, eventId: null }))} title='Order Details'>
                <OrderDetailModal tranId={order.tranId} eventId={order.eventId} onUpdate={() => setOrder(s => ({ ...s, refreshToken: s.refreshToken + 1 }))} />
            </FullScreenModal>

        </Root>
    )
}


const Root = styled(Card)({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width :'100%'
});

const Status = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
});

const TableCellHeader = styled(TableCell)(({ theme }) => ({
    fontWeight: 'bold'
}));

const EventTitleCell = styled(TableCell)(({ theme }) => ({
    maxWidth: 200,
    [theme.breakpoints.down('sm')]: {
        maxWidth: 300
    }
}));

const EventTitleText = styled(Typography)({
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
});

const BodyRow = styled(TableRow)(({ theme }) => ({
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
        cursor: 'pointer'
    }
}));