import React, { useState, useEffect } from 'react';

// Material-UI
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell as _TableCell } from '@material-ui/core';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import { styled, CircularProgress, Typography, Box } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';

// Components
import Tooltip from 'components/popups/Tooltip';
import OrderDetailModal from 'components/orders/OrderDetailModal';
import FullScreenModal from 'components/popups/FullScreenModal';

// Utility
import fetchExpresso from 'utility/fetchExpresso';
import moment from 'moment-timezone';



export default function RecentScans() {

    const [state, setState] = useState({
        data: null,
        loading: true,
        error: false
    });

    const { data, loading, error } = state;

    const [order, setOrder] = useState({ tranId: null, eventId: null });


    useEffect(() => {
        fetchExpresso(`/admin/scans?limit=10&tz=${moment.tz.guess()}`)
            .then(async res => {
                if (res.status !== 200) {
                    setState(s => ({ ...s, loading: false, error: true }));
                    return;
                }
                const data = await res.json();
                setState(s => ({ ...s, data: data, loading: false, error: false }));
            })
            .catch(e => {
                setState(s => ({ ...s, loading: false, error: true }));
            })
    }, []);

    if (loading) {
        return (
            <Root>
                <Status>
                    <CircularProgress disableShrink />
                </Status>
            </Root>
        );
    }

    if (error) {
        return (
            <Root>
                <Status>
                    <Typography>Unable to load scans</Typography>
                </Status>
            </Root>
        );
    }


    return (
        <Root>

            <CardHeader title='Recent Scans' />

            <CardContent>

                <TableContainer component={Box}>

                    <Table size='small'>

                        <TableHead>

                            <TableRow>
                                <TableCellHeader></TableCellHeader>
                                <TableCellHeader style={{ paddingLeft: 0 }} variant='head'>RESULT</TableCellHeader>
                                <TableCellHeader variant='head'>EVENT</TableCellHeader>
                                <TableCellHeader variant='head'>CUSTOMER</TableCellHeader>
                                <TableCellHeader variant='head'>TICKET</TableCellHeader>
                                <TableCellHeader></TableCellHeader>
                                <TableCellHeader style={{ paddingLeft: 0 }} variant='head'>DEVICE ID</TableCellHeader>
                                <TableCellHeader variant='head' align='right'>WHEN</TableCellHeader>

                            </TableRow>

                        </TableHead>

                        <TableBody>

                            {data.map((scan, index) => (
                                <BodyRow key={index} onClick={() => setOrder(order => ({ ...order, tranId: scan.tran_id, eventId: scan.event_id }))}>
                                    <TableCellIcon>
                                        {(scan.scan_status_id >= 100 && scan.scan_status_id <= 199)
                                            ? <GreenIndicator />
                                            : <RedIndicator />
                                        }
                                    </TableCellIcon>
                                    <TableCellLabel width={240}>{scan.code_desc}</TableCellLabel>
                                    <TableCellEllipsis>
                                        <TextEllipsis noWrap variant='body2'>{scan.event_title}</TextEllipsis>
                                    </TableCellEllipsis>
                                    <TableCell>{scan.first_name[0]}. {scan.last_name}</TableCell>
                                    <TableCellEllipsis>
                                        <TextEllipsis noWrap variant='body2'>{scan.prod_name}</TextEllipsis>
                                    </TableCellEllipsis>
                                    <TableCellIcon>
                                        {scan.scanner_name
                                            ? (
                                                <Box display='flex' alignItems='center'>
                                                    <Tooltip message={scan.scanner_name}>
                                                        <PersonIcon fontSize='small' color='secondary' />
                                                    </Tooltip>
                                                </Box>
                                            )
                                            : ''
                                        }
                                    </TableCellIcon>
                                    <TableCellLabel width={100}>{scan.device_id}</TableCellLabel>
                                    <TableCell align='right' width={100}>{moment(scan.device_scanned_at).fromNow()}</TableCell>
                                </BodyRow>
                            ))}

                        </TableBody>

                    </Table>

                </TableContainer>

            </CardContent>

            <FullScreenModal open={order.tranId ? true : false} onClose={() => setOrder(s => ({ ...s, tranId: null, eventId: null }))} title='Order Details'>
                <OrderDetailModal tranId={order.tranId} eventId={order.eventId} />
            </FullScreenModal>

        </Root>
    )
}


const Root = styled(Card)({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width :'100%'
});

const Status = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
});

const TableCellIcon = styled(_TableCell)(({ theme }) => ({
    paddingRight: 0,
    width: 24
}));

const TableCellLabel = styled(_TableCell)(({ theme }) => ({
    paddingLeft: 0,
    whiteSpace: 'nowrap'
}));

const TableCellEllipsis = styled(_TableCell)(({ theme }) => ({
    maxWidth: 280
}));

const TextEllipsis = styled(Typography)({
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
});


const GreenIndicator = styled(Box)(({ theme }) => ({
    height: 12,
    width: 12,
    backgroundColor: theme.palette.success.main,
    borderRadius: 12,
}));

const RedIndicator = styled(Box)(({ theme }) => ({
    height: 12,
    width: 12,
    backgroundColor: theme.palette.error.main,
    borderRadius: 12,
}));

const TableCell = styled(_TableCell)(({ theme }) => ({
    whiteSpace: 'nowrap'
}));

const TableCellHeader = styled(TableCell)(({ theme }) => ({
    fontWeight: 'bold'
}));

const BodyRow = styled(TableRow)(({ theme }) => ({
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
        cursor: 'pointer'
    }
}));