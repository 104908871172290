import React from 'react';

// Material UI
import Snackbar from '@material-ui/core/Snackbar';

// Utility 
import { useNotification } from 'context/notification';
import { Alert } from '@material-ui/lab';


export default function Notification() {
    const { notification, closeNotification } = useNotification();

    return (
        <Snackbar
            open={notification.open}
            anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
            onClose={closeNotification}
            autoHideDuration={4000}
        >
            {notification.open ? (
                <Alert
                    variant='filled'
                    severity={notification.severity || 'info'}
                >
                    {notification.message}
                </Alert>
            ) : null}
        </Snackbar>
    )
}
