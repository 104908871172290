import React, { useState } from 'react';

import { styled, Box } from '@material-ui/core';

// Components
import { AdminPage, ControlBar, ControlBarButton, ControlBarHeader } from 'components/ui/layout';
import UserList from 'components/UserList';
import FullScreenModal from 'components/popups/FullScreenModal';
import UserForm from 'components/UserForm';



export default function Users() {
    // State
    const [state, setState] = useState({
        modalOpen: false,
        dependency: 0 // incrementing this value will cause UserList to fetch all hosts and re render
    });


    function handleModalClose() {
        setState({...state, modalOpen: false, selectedUser: null});
    }

    function handleModalOpen() {
        setState({...state, modalOpen: true});
    }

    const handleUserUpdate = () => {
        const newDependency = state.dependency + 1;
        setState({...state, modalOpen: false, selectedUser: null, dependency: newDependency });
    }

    const handleUserSelect = (id) => {
        setState({ ...state, modalOpen: true, selectedUser: id });
    }

    // Destructure State
    const { modalOpen, selectedUser } = state;


    return (
        <AdminPage>

            <Container>

                <ControlBar>
                    <ControlBarHeader>Users</ControlBarHeader>
                    <ControlBarButton onClick={handleModalOpen}>New User</ControlBarButton>
                </ControlBar>

                <Box display={'flex'} flex={1} width={'100%'}>
                    <UserList dependency={state.dependency} onUserSelect={handleUserSelect} />
                </Box>

                <FullScreenModal open={modalOpen} onClose={handleModalClose} title={selectedUser ? 'Edit User' : 'Create User'}>
                    <UserForm userId={selectedUser} onUpdate={handleUserUpdate} onCancel={handleModalClose} />
                </FullScreenModal>

            </Container>
                
        </AdminPage>
    )
}


const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
}));