import React, { useState } from 'react';

// Material-UI
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { styled, Box, Paper, CircularProgress, Typography } from '@material-ui/core';

// Components
import { ControlBar, ControlBarHeader } from 'components/ui/layout';
import OrderDetailModal from 'components/orders/OrderDetailModal';
import FullScreenModal from 'components/popups/FullScreenModal';
import { PaymentIcon, DiscountIcon, CommentIcon } from 'components/ui/icons';

// Utility
import useExpresso from 'hooks/useExpresso';
import { formatPrice } from 'utility/numbers';
import moment from 'moment-timezone';


export default function RecentOrdersByHost(props) {
    const { hostId } = props;
    const [state, setState] = useState({ tranId: null, eventId: null, refreshToken: 0 });
    const [data, loading, error] = useExpresso(`/apiv2/hosts/${hostId}/recent_orders?limit=5`, null, null, null, [hostId, state.refreshToken]);


    const handleUpdate = () => {
        setState(s => ({ ...s, refreshToken: state.refreshToken + 1 }));
    }

    const setOrder = (tranId, eventId) => {
        setState(s => ({ ...s, tranId, eventId }));
    }


    // Return recent orders
    return (
        <Root>

            <ControlBar>
                <ControlBarHeader>Recent Orders</ControlBarHeader>
            </ControlBar>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableHeadCell width={10}></TableHeadCell>
                            <TableHeadCell width={50}>#</TableHeadCell>
                            <TableHeadCell width={50} align='left'>DETAILS</TableHeadCell>
                            <TableHeadCell align='left'>EVENT</TableHeadCell>
                            <TableHeadCell width={80} align='right'>TOTAL</TableHeadCell>
                            <TableHeadCell width={80} align='right'>TIX</TableHeadCell>
                            <TableHeadCell>NAME</TableHeadCell>
                            <TableHeadCell>WHEN</TableHeadCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {data && data.length > 0 && data.map(order => (
                            <TRow key={order.tran_id} onClick={() => setOrder(order.tran_id, order.event_id)}>
                                <TableBodyCell>
                                    <PaymentIcon status={order.status_financial} message={order.decline_message} />
                                </TableBodyCell>
                                <TableBodyCell>F{order.tran_id}</TableBodyCell>
                                <TableBodyCell align='left'>
                                    <Box display='flex'>
                                        <DiscountIcon code={order.discount_code} compedBy={order.comped_by} />
                                        <CommentIcon count={order.comment_count} />
                                    </Box>
                                </TableBodyCell>
                                <TableBodyCell>{order.event_title}</TableBodyCell>
                                <TableBodyCell align='right'>{formatPrice(order.total)}</TableBodyCell>
                                <TableBodyCell align='right'>{order.total_tickets}</TableBodyCell>
                                <TableBodyCell>{`${order.first_name[0]}. ${order.last_name}`}</TableBodyCell>
                                <TableBodyCell>{moment(order.created_at).fromNow()}</TableBodyCell>
                            </TRow>
                        ))}

                        {data && data.length === 0 && !loading && !error && (
                            <TableRow>
                                <TableCell align='center' colSpan={7}>
                                    <Typography variant='subtitle2'>No orders found</Typography>
                                </TableCell>
                            </TableRow>
                        )}

                        {loading && (
                            <TableRow>
                                <TableCell align='center' colSpan={7}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        )}
                        
                        {error && (
                            <TableRow>
                                <TableCell align='center' colSpan={7}>
                                    <Typography variant='subtitle2'>There was a problem getting your orders</Typography>
                                </TableCell>
                            </TableRow>
                        )}

                    </TableBody>
                </Table>
            </TableContainer>

            <FullScreenModal open={state.tranId ? true : false} onClose={() => setOrder(null, null)} title='Order Details'>
                <OrderDetailModal tranId={state.tranId} eventId={state.eventId} onUpdate={handleUpdate} />
            </FullScreenModal>
        </Root>
    )
}

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    minHeight: 200
});

const TRow = styled(TableRow)(({ theme }) => ({
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
        cursor: 'pointer'
    }
}));

const TableHeadCell = styled(TableCell)(({ theme }) => ({
    ...theme.typography.subHeader
}));

const TableBodyCell = styled(TableCell)({
    whiteSpace: 'nowrap',
});