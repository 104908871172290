import React, { useState, useEffect } from 'react';
import { Page } from 'components/ui/layout';

// Material UI
import { useTheme, styled, List, ListItem, ListItemText, Box, Typography, Grid, ListItemIcon } from '@material-ui/core';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';

// Components
import { SearchBar } from 'components/ui/inputs';

// Utility
import { useAuth } from 'context/auth';
import { Redirect } from 'react-router-dom';
import fetchExpresso from 'utility/fetchExpresso';
import { normalizeCharacters } from 'utility/strings';


export default function HostSelect() {
    const { auth, setHost, logout } = useAuth();

    if (auth.hostId) {
        return <Redirect to='/' />
    } else {
        return <HostPicker logout={logout} onSelectHost={setHost} />
    }
}


function HostPicker(props) {
    const [hosts, setHosts] = useState([]);
    const [error, setError] = useState(false);    
    const [search, setSearch] = useState('');
    
    const theme = useTheme();


    useEffect(() => {
        fetchExpresso(`/api/auths/host_select`)
            .then(async res => {
                if (res.status === 401 || res.status === 403) {
                    props.logout();
                    return;
                }

                if (res.status !== 200) throw new Error();

                const hosts = await res.json();

                setHosts(hosts);
            })
            .catch(() => setError(true))
            // eslint-disable-next-line
    }, []);


    const handleHostSelect = (h) => {
        props.onSelectHost(h.host_id, h.host_name)
    };

    if (error) {
        return (
            <Box display='flex' width='100%' justifyContent='center'>
                <Box p={[2, 4]} maxWidth={theme.breakpoints.values.lg}>
                    <Typography>Unable to load hosts. Try reloading the page.</Typography>
                </Box>
            </Box>
        )
    }


    let filteredHosts = hosts;

    if (search.trim()) {
        const searchStr = normalizeCharacters(search.trim()); // Normalize string to remove accents and diacritics

        const searchRegex = new RegExp(searchStr.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');

        filteredHosts = hosts.filter((h) => {
            const hostName = normalizeCharacters(h.host_name);
            return searchRegex.test(hostName);
        });
    }

    const activeHosts = filteredHosts
        .filter(h => h.future_event_count > 0)
        .sort((a,b) => b.live_event_count - a.live_event_count);

    const inactiveHosts = filteredHosts
        .filter(h => h.future_event_count === 0);


    return (
        <Page width='md'>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <SearchBar
                        autoFocus
                        value={search}
                        onChange={setSearch}
                        placeholder='Find Host'
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Accordion defaultExpanded>

                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Active Hosts</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <HostList>
                                {activeHosts.length > 0 && activeHosts.map(host => (
                                    <ListItem key={host.host_id} button onClick={() => handleHostSelect(host)}>
                                        {host.live_event_count === 1 ? (<>
                                            <ListItemText disableTypography>
                                                <LiveHost>{host.host_name}</LiveHost>
                                            </ListItemText>
                                            <ListItemIcon>
                                                <LiveIcon />
                                            </ListItemIcon>
                                        </>) : (
                                            <ListItemText>{host.host_name}</ListItemText>
                                        )}
                                    </ListItem>
                                ))}
                            </HostList>
                        </AccordionDetails>

                    </Accordion>
                </Grid>

                <Grid item xs={12} md={6}>
                    <AccordionGrey defaultExpanded>

                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Inactive Hosts</Typography>
                        </AccordionSummary>
                        
                        <AccordionDetails>
                            <HostList>
                                {inactiveHosts.length > 0 && inactiveHosts.map(host => (
                                    <ListItem key={host.host_id} button onClick={() => handleHostSelect(host)}>
                                        <ListItemText>{host.host_name}</ListItemText>
                                    </ListItem>
                                ))}
                            </HostList>
                        </AccordionDetails>

                    </AccordionGrey>
                </Grid>
            </Grid>

        </Page>
    )
}


const HostList = styled(List)(({ theme }) => ({
    width: '100%'
}));

const AccordionGrey = styled(Accordion)(({ theme }) => ({
    backgroundColor: theme.palette.grey[300]
}));

const LiveHost = styled(Typography)(({ theme }) => ({
    color: theme.palette.success.main,
    fontWeight: 'bold'
}));

const LiveIcon = styled(EventAvailableIcon)(({ theme }) => ({
    color: theme.palette.success.main,
}));